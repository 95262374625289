import React, { createContext, useContext, useState, useEffect, useRef, useCallback } from "react";
import { useAuth } from "./AuthProvider";

const AppContext = createContext();
export const useApp = () => useContext(AppContext);

export const AppProvider = ({ children }) => {
  const { user, accessToken } = useAuth();
  const [boardData, setBoardData] = useState();
  const [patientsMHR, setPatientsMHR] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState();
  const [boardDate, setBoardDate] = useState(new Date().toISOString().slice(0, 10));
  const [patientsMedicine, setPatientsMedicine] = useState([]);
  const [selectedPatientObj, setSelectedPatientObj] = useState();
  const [pastSidebarToggle, setPastSidebarToggle] = useState(false);
  const [organizedAppointments, setOrganizedAppointments] = useState();
  const [appointments, setAppointments] = useState();
  const [cardDropDownCondition, setCardDropDownCondition] = useState("");
  const [medicationGroup, setMedicationGroup] = useState();
  const [selectedClinic, setSelectedClinic] = useState();
  const [clinics, setClinics] = useState();
  const [doctor, setDoctor] = useState();
  const [searchMedicineData, setSearchMedicineData] = useState([]);
  const [searchTestLab, setSearchTestLab] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const menuPortalRef = useRef(null);
  const [menuPortalTarget, setMenuPortalTarget] = useState(null);
  const [mediTableContent, setMediTableContent] = useState([]);
  const [addMedicineSelecteGroupsAdvice, setAddMedicineSelecteGroupsAdvice] = useState();
  const [selectedAppointment, setSelectedAppointment] = useState();
  const [isModalOpenSearchAppointment, setIsModalOpenSearchAppointment] = useState(false);
  const [mediTableContentLabTest, setMediTableContentLabTest] = useState([]);



  const fetchRecordDataCallback = useCallback((recordIds) => {
    fetchRecordData(recordIds);
  }, []);

  const fetchMedicineDataCallback = useCallback((medicationIds) => {
    fetchMedicineData(medicationIds);
  }, []);

  const fetchPatientObjCallback = useCallback((id) => {
    fetchPatientObj(id);
  }, []);


  const convertApiResponseToInitialDataFormat = (response) => {
    const tasks = {};
    const columns = {
      "UPCOMING": {
        id: "UPCOMING",
        title: "UPCOMING",
        taskIds: []
      },
      "IN-PROGRESS": {
        id: "IN-PROGRESS",
        title: "IN-PROGRESS",
        taskIds: []
      },
      "FOR-TESTING": {
        id: "FOR-TESTING",
        title: "FOR-TESTING",
        taskIds: []
      },
      "COMPLETED": {
        id: "COMPLETED",
        title: "COMPLETED",
        taskIds: []
      }
    };
    const columnOrder = ["UPCOMING", "IN-PROGRESS", "FOR-TESTING", "COMPLETED"];
    if (response) {
      response.forEach((appointment, index) => {
        const taskId = appointment._id;
        const padWithZero = (number) => number.toString().padStart(2, "0");
        const date = new Date(appointment.dateOfAppointment);
        const time = `${date.getUTCHours()}:${padWithZero(date.getUTCMinutes())} ${date.getUTCHours() >= 12 ? "PM" : "AM"
          }`;
        tasks[taskId] = {
          id: taskId,
          content: appointment.patientName,
          tag: appointment.tags[0],
          time: time,
          patientId: appointment.patientId,
          status: appointment.status
        };
        // console.log('APPOINTMENT STAUS', appointment.status)
        if (appointment.status) {
          if (columns[appointment.status]) {
            columns[appointment.status].taskIds.push(taskId);
          }
        }
        else {
          columns["UPCOMING"].taskIds.push(taskId);
        }
      });
    }
    return { tasks: tasks, columns: columns, columnOrder: columnOrder };
  };


  const organiseAppointments = async (fetchedAppointments) => {
    const newOrganizedAppointments = fetchedAppointments.reduce(
      (acc, appointment) => {
        const dateKey = appointment.dateOfAppointment.split("T")[0];
        if (!acc[dateKey]) {
          acc[dateKey] = [];
        }
        const isDuplicate = acc[dateKey].some(
          (existingAppointment) => existingAppointment._id === appointment._id
        );
        if (!isDuplicate) {
          acc[dateKey].push(appointment);
        }
        return acc;
      },
      organizedAppointments ? organizedAppointments : {}
    );
    setOrganizedAppointments(newOrganizedAppointments);
    setSelectedPatient(fetchedAppointments[0].patientId, "PATIENT ID");//Call API to get appointment obj and then use the patientID
  };

  const getAppointments = async () => {
    const filterDate = new Date(boardDate)
    // console.log(selectedClinic, "SELECTED CLINIC")
    const ids = selectedClinic.appointmentUsage.find(usage => usage.doctorId.toString() === user._id.toString())
      .appointments.map(appointment => appointment.appointmentId.toString()).join(',')
    // console.log('Ids2',ids2)
    // const ids = user.appointments
    // .filter(appointment => new Date(appointment.date).toDateString() === filterDate.toDateString())
    // .map(appointment => appointment._id.toString()).join(",");

    const url = `https://dev.gateway.cohesivehealth.in/appointments/${ids}`;
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${accessToken}`
      }
    };
    fetch(url, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`get appointment error: ${response.status}`);
        }
        // console.log("APPOINTMENT API",response)
        return response.json();
      })
      .then((fetchedAppointments) => {
        setAppointments(fetchedAppointments);
        // console.log(fetchedAppointments, selectedClinic.appointmentUsage.find(object => object.doctorId.toString() === user._id.toString()))
        organiseAppointments(fetchedAppointments);
      })
      .catch((e) => {
        console.error(`get appointment error: ${e.message}`);
      });
  };



  const fetchPatientObj = async (id) => {
    if (id) {
      return fetch(
        `https://dev3.backend.cohesivehealth.in/patients/${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${accessToken}`
          }
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          // console.warn("SELECCTED PATIENT OBJ 1", data[0]);
          setSelectedPatientObj(data[0]);
        })
        .catch((error) => {
          console.error("Error fetching Patient data:", error);
        });
    } else {
      console.error("Patient ID is not available.");
    }
  };



  const fetchRecordData = async (ids) => {
    if (ids) {
      fetch(`https://dev3.backend.cohesivehealth.in/mhr/${ids}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${accessToken}`
        }
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`fatch respons error: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          setPatientsMHR(data);
        })
        .catch((error) => {
          console.error("Error fetching Record data:", error);
        });
    } else {
      console.log("User Records not available yet.");
      setPatientsMHR([]);
    }
  };

  // fatch medicine data
  const fetchMedicineData = async (ids) => {
    const today = new Date().toISOString().split("T")[0];
    // console.log(today);
    const apiUrl = `https://dev3.backend.cohesivehealth.in/medications/${ids}?startDate=${today}&endDate=${today}`;
    if (ids) {
      fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${accessToken}`
        }
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`fatch respons error medicine: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          setPatientsMedicine(data);
        })
        .catch((error) => {
          console.error("Error fetching medicine data:", error);
        });
    } else {
      console.log("User medicine not available yet.");
      setPatientsMedicine([]);
    }
  };


  const fetchMedicationGroupData = async (id) => {
    if (id) {
      try {
        const response = await fetch(`https://dev3.backend.cohesivehealth.in/medicationGroups/${id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${accessToken}`
          }
        });
        if (!response.ok) {
          throw new Error(`Fetch response error: ${response.status}`);
        }
        const data = await response.json();
        setMedicationGroup(data);
      } catch (error) {
        console.error("Error fetching Medication Group data:", error);
      }
    } else {
      console.log("Medication Group data not available yet.");
      setMedicationGroup([]);
    }
  };

  // Fatch Clinic api
  const fetchClinicData = async (id) => {
    if (id) {
      try {
        const response = await fetch(`https://dev3.backend.cohesivehealth.in/clinics/${id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${accessToken}`
          }
        });
        if (!response.ok) {
          throw new Error(`Fetch response error: ${response.status}`);
        }
        const data = await response.json();
        setClinics(data);
        setSelectedClinic(data[0])
        // console.log("CLINICS", data)
      } catch (error) {
        console.error("Error fetching Clinic data:", error);
      }
    } else {
      console.log("Clinic data not available yet.");
      // setClinics();
    }
  };

  // SEARCH MEADICINE DATA
  const fetchSearchMedicineAPI = async (searchQuery) => {
    const apiUrl = `https://dev3.backend.cohesivehealth.in/pharmaSKU/search/sku?searchQuery=${searchQuery}`;
    try {
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${accessToken}`
        }
      });
      if (!response.ok) {
        throw new Error(`Fetch error: ${response.status} - ${response.statusText}`);
      }
      const data = await response.json();
      setSearchMedicineData(data);
    } catch (error) {
      console.error("Error fetching search medicine data:", error.message);
    }
  };

  // SEARCH LAB TEST DATA
  const fetchSearchTestLabAPI = async (searchQuery) => {
    const apiUrl = `https://dev3.backend.cohesivehealth.in/labtest/search/testname?searchQuery=${searchQuery}`;
    if (!searchQuery) {
      console.error("searchQuery is empty or undefined");
      return;
    }

    try {
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${accessToken}`
        }
      });
      if (!response.ok) {
        throw new Error(`Fetch error: ${response.status} - ${response.statusText}`);
      }
      const data = await response.json();
      setSearchTestLab(data);
      console.log("Test Lab", setSearchTestLab)
    } catch (error) {
      console.error("Error fetching search lab test data:", error.message);
    }
  };

  // fatch doctor data //
  const fetchDoctorData = async (id) => {
    if (id) {
      try {
        const response = await fetch(`https://dev3.backend.cohesivehealth.in/doctors/${id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${accessToken}`
          }
        });

        if (!response.ok) {
          throw new Error(`Fetch response error: ${response.status}`);
        }
        const data = await response.json();
        setDoctor(data);
        // console.log("DOCTORS", data);
      } catch (error) {
        console.error("Error fetching Doctor data:", error);
      }
    } else {
      console.log("Doctor data not available yet.");
    }
  };

  useEffect(() => {
    if (selectedClinic) {
      fetchDoctorData(selectedClinic.doctors.join(","));
    }
  }, [selectedClinic]);


  useEffect(() => {
    fetchSearchMedicineAPI(searchQuery);
    fetchSearchTestLabAPI(searchQuery);
    setMenuPortalTarget(menuPortalRef.current);
  }, [menuPortalRef.current, searchQuery]);


  useEffect(() => {
    if (user) {
      if (user.medicationGroups) {
        fetchMedicationGroupData(user.medicationGroups.join(","));
      }
    }
  }, [user]);




  useEffect(() => {
    if (user) {
      if (user.clinics) {
        fetchClinicData(user.clinics.join(","));
      }
    }
  }, [user]);

  useEffect(() => {
    if (user && selectedClinic) {
      getAppointments();
    }
  }, [boardDate, selectedClinic, user]);

  useEffect(() => {
    if (organizedAppointments) {
      const newBoardData = convertApiResponseToInitialDataFormat(
        organizedAppointments[boardDate]
      );
      // console.log(organizedAppointments,"organizedAppointments")
      setBoardData(newBoardData);
    }

  }, [organizedAppointments, boardDate]);

  useEffect(() => {
    if (selectedPatient) {
      // console.log('SELECTED PATEINT LOOP')
      fetchPatientObjCallback(selectedPatient)
    }
  }, [selectedPatient]);



  useEffect(() => {
    // console.log(selectedPatientObj)
    if (selectedPatientObj) {
      fetchRecordDataCallback(selectedPatientObj.medicalHealthRecords.join(","));
      fetchMedicineDataCallback(selectedPatientObj.medications.join(","));
    }
  }, [selectedPatientObj, fetchRecordDataCallback, fetchMedicineDataCallback]);

  const value = {
    getAppointments,
    clinics,
    setClinics,
    selectedClinic,
    organizedAppointments,
    boardData,
    setBoardData,
    selectedPatientObj,
    patientsMHR,
    patientsMedicine,
    setSelectedPatientObj,
    setSelectedPatient,
    setBoardDate,
    pastSidebarToggle,
    setPastSidebarToggle,
    cardDropDownCondition,
    setCardDropDownCondition,
    medicationGroup,
    setMedicationGroup,
    searchMedicineData,
    setSearchMedicineData,
    searchQuery,
    setSearchQuery,
    mediTableContent,
    setMediTableContent,
    fetchClinicData,
    addMedicineSelecteGroupsAdvice,
    setAddMedicineSelecteGroupsAdvice,
    selectedAppointment,
    setSelectedAppointment,
    isModalOpenSearchAppointment,
    setIsModalOpenSearchAppointment,
    appointments,
    setSelectedClinic,
    doctor,
    setDoctor,
    searchTestLab,
    setSearchTestLab,
    mediTableContentLabTest,
    setMediTableContentLabTest
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};