import React, { useState, useEffect } from 'react';
import './ContactVerification.css';
import PatientForm from './PatientForm';
import { AiOutlineClose } from 'react-icons/ai';
const config = require('../../config.json')

const smsAuthKey=config.smsAuthKey
const smsTemplateId=config.smsTemplateId

// Loader Component
const Loader = () => (
  <div className="contact-verification-loader-overlay">
    <div className="contact-verification-loader"></div>
  </div>
);

function ContactVerification({ setIsContactVerificationOpen }) {
  const [isOTPPage, setIsOTPPage] = useState(false);
  const [otpCode, setOtpCode] = useState(['', '', '', '']);
  const [mobileNumber, setMobileNumber] = useState('');
  const [countryCode, setCountryCode] = useState('+91');
  const [isLoading, setIsLoading] = useState(false);
  const [showPatientForm, setShowPatientForm] = useState(false);
  const [resendDisabled, setResendDisabled] = useState(false);
  const [timer, setTimer] = useState(45);


  const sendOtp = () =>{

    const requestOptions = {
        method: "POST",
        headers: {
          "Accept": "application/json",
          'content-type': 'application/json',
          "authkey" : `${smsAuthKey}`
        },
      };
  
    const url = `https://control.msg91.com/api/v5/otp?template_id=${smsTemplateId}&mobile=${countryCode.replace('+','')}${mobileNumber}`
    console.log(requestOptions)
    fetch(url, requestOptions)
    .then(response => response.json())
    .then(response => console.log(response))
    .catch((e) => {
        console.error(`OTP Send Error: ${e.message}`);
      })
  }

  
  const verifyOtp = async (otp) => {
    try {
        const response = await fetch(`https://control.msg91.com/api/v5/otp/verify?otp=${otp}&mobile=${countryCode.replace('+','')}${mobileNumber}`, {
            method: 'GET',
            headers: {
              'authkey': `${smsAuthKey}`,
              'Accept': 'application/json'
            }
        });
        const data = await response.json();
        console.log(data);

        if (data.type === 'success') { 
            // patientUser
            setShowPatientForm(true);
            return true; // Indicate success
        } else {
            const error = { status: 401, message: 'OTP verification failed' };
            return false; // Indicate failure
        }
    } catch (e) {
        console.error(`OTP Verification Error: ${e.message}`);
        return false; // Indicate failure on exception
    }
  }

  const handleNumberSubmit = async (e) => {
    e.preventDefault();
    await sendOtp()
    setIsOTPPage(true);
  };

  const handleOtpChange = (e, index) => {
    const value = e.target.value;
    const inputs = document.querySelectorAll('.otp-box');

    // Handle digit entry
    if (/^\d$/.test(value)) {
      const newOtpCode = [...otpCode];
      newOtpCode[index] = value;
      setOtpCode(newOtpCode);

      // Focus the next input
      if (index < inputs.length - 1) {
        inputs[index + 1].focus();
      }
    }

    // Handle backspace and move to previous field
    if (value === '' && e.nativeEvent.inputType === 'deleteContentBackward') {
      const newOtpCode = [...otpCode];
      newOtpCode[index] = '';
      setOtpCode(newOtpCode);

      if (index > 0) {
        inputs[index - 1].focus();
      }
    }
  };


  const handleOtpSubmit = (e) => {
    e.preventDefault();
    const enteredOtp = otpCode.join('');
    console.log(enteredOtp)
    verifyOtp(enteredOtp)
    if (enteredOtp === '1234') {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
        setShowPatientForm(true);
      }, 2000);
    } else {
      alert('Invalid OTP, please try again');
    }
  };

  const handleResendOtp = () => {
    setResendDisabled(true);
    setTimer(45); // Reset timer to 30 seconds
    // Simulate API call for resending OTP
    setTimeout(() => {
      console.log('OTP Resent');
      setResendDisabled(false);
    }, 45000);
  };

  // Timer effect
  useEffect(() => {
    let interval;
    if (resendDisabled) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    }
    if (timer === 0) {
      setResendDisabled(false);
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [resendDisabled, timer]);

  const closeModal = () => {
    setIsContactVerificationOpen(false);
    // setIsOTPPage(false);
  };

  return (
    <div className='popup-overlay'>
      {isLoading && <Loader />}
      {!showPatientForm ? (
        <div className='contact-verification-popup-container'>
          {!isOTPPage ? (
            <>
              <div className='contact-verification-close-icon-container'>
                <AiOutlineClose className="contact-verification-close-icon" onClick={closeModal} />
              </div>
              <h2 className='popup-container-main-heading'>Enter Your Mobile Number</h2>
              <form onSubmit={handleNumberSubmit}>
                <div className='input-group'>
                  <select
                    value={countryCode}
                    onChange={(e) => setCountryCode(e.target.value)}
                    className='country-code-dropdown'
                  >
                    <option value='+91'>+91 (India)</option>
                  </select>
                  <input
                    type='tel'
                    placeholder='Mobile Number'
                    value={mobileNumber}
                    onChange={(e) => setMobileNumber(e.target.value)}
                    required
                    className='contact-mobile-input'
                  />
                </div>
                <button type='submit' className='submit-btn'>
                  Submit
                </button>
              </form>
            </>
          ) : (
            <>
              <div className='contact-verification-close-icon-container'>
                <AiOutlineClose className="contact-verification-close-icon" onClick={() => { setIsOTPPage(false) }} />
              </div>
              <h2 className="popup-container-main-heading">Enter OTP</h2>
              <form onSubmit={handleOtpSubmit}>
                <div className='otp-group'>
                  {otpCode.map((digit, index) => (
                    <input
                      key={index}
                      type="text"
                      value={digit}
                      onChange={(e) => handleOtpChange(e, index)}
                      maxLength={1}
                      required
                      className="otp-box"
                      onFocus={(e) => e.target.select()} // Auto select current value
                    />
                  ))}
                </div>
                <div className='btn-container-otp'>
                  <button type='submit' className='submit-btn'>Verify OTP</button>
                  <button
                    type='button'
                    onClick={handleResendOtp}
                    className='submit-btn'
                    disabled={resendDisabled}
                  >
                    {resendDisabled ? `Re-send (${timer}s)` : 'Re-send'}
                  </button>
                </div>
              </form>
            </>
          )}
        </div>
      ) : (
        <PatientForm onClose={closeModal} />
      )}
    </div>
  );
}

export default ContactVerification;
