// src/components/AddTestPopup.js
import React, { useState, useRef, useEffect, useContext } from "react";
import './AddtestPopup.css';
import Dropdown from "react-dropdown";
import DeleteIcon from "../../../../Setting/SettingsAssets/Delete.svg";
import searchIcon from "./Assets/Search.png";
import { useApp } from "../../../../../AppProvider";

const AddTestPopup = ({ setIsTestModalOpen }) => {
  const { searchTestLab, setSearchTestLab, mediTableContentLabTest, setMediTableContentLabTest, setSearchQuery, searchQuery } = useApp();
  const [addTestLabOption, setAddtestLabOption] = useState();

  const handleDeleteClick = (serialNo) => {
    const updatedMediTableContent = mediTableContentLabTest.filter(
      (item) => item.serialNo !== serialNo
    );
    setMediTableContentLabTest(
      updatedMediTableContent.map((item, index) => ({
        ...item,
        serialNo: (index + 1).toString()
      }))
    );
  };

  const handleResultClick = (item) => {
    setSearchTestLab([]);
    setSearchQuery(item._id);
    const newLabTest = {
      serialNo: (mediTableContentLabTest.length + 1).toString(),
      Id: item.testId,
      department: item.department,
      testName: item.testName
    };
    setMediTableContentLabTest([...mediTableContentLabTest, newLabTest]);
    setSearchQuery('');
  };


  const labTestGroupOptions = () => { }

  const handleDropdownChange = () => {
    setAddtestLabOption();
  }

  const handleSearchLabChange = (event) => {
    const searchQuery = event.target.value;
    setSearchQuery(searchQuery);
  };

  console.log("searchTestLab", searchTestLab)

  return (
    <div className="test-overlay_page">
      <div className="test-main-container">
        <h2 className="test-main-container-heading">Tests</h2>
        <div className="test-input-type-search">
          <div className="test-search-container">
            <img src={searchIcon} className="test-search-icon" alt="search" />
            <input
              className="test-input-type-align"
              type="text"
              placeholder="search Test"
              value={searchQuery}
              onChange={handleSearchLabChange}
            />
            {searchTestLab.length > 0 && (
              <div className="medicine-search-results">
                {searchTestLab.map((item, index) => (
                  <div className="medicine-brandsearch" key={index}
                    onClick={() => handleResultClick(item)} >
                    {item.testName}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="test-search-container">
            <Dropdown
              controlClassName="my-dropdown-control_groups"
              options={labTestGroupOptions}
              onChange={handleDropdownChange}
              value={addTestLabOption}
              placeholder="Select Test Group"
            />
          </div>
        </div>
        <div className="test-table-wrapper">
          <div className="test-table-section">
            <div className="test-table-container">
              <div className="test-table-content">
                <table className="test-table">
                  <thead>
                    <tr className="test-tableheader">
                      <th className="test-header_text">S.No.</th>
                      <th className="test-header_text">Test Name</th>
                      <th className="test-header_text">Lab Name</th>
                      <th className="test-header_text delete-test-icon">Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {mediTableContentLabTest.length > 0 ? (
                      mediTableContentLabTest.map((group) => (
                        <tr key={group.serialNo}>
                          <td className="test-table_el">{group.serialNo}</td>
                          <td className="test-table_el">{group.testName}</td>
                          <td className="test-table_el">{group.department}</td>
                          <td className="test-table_el test-delete-icon">
                            <img
                              className="test-delete_icon"
                              src={DeleteIcon}
                              alt="deleteicon"
                              onClick={() => handleDeleteClick(group.serialNo)}
                            />
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="4" className="test-no_tests_selected">
                          No Tests Selected
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="test-bottom-button-div">
          <button className="test-bottom-button test-bottom-button-right" onClick={() => setIsTestModalOpen(false)}>Cancel</button>
          <button className="test-bottom-button test-bottom-button-left" onClick={() => setIsTestModalOpen(false)}>Add Test</button>
        </div>
      </div>
    </div>
  );
};

export default AddTestPopup;
