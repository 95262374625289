import React, { useState, useRef, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import CalendarIcon from "./images/Calendar.png";
import Prescription from "../../../assets/Prescription.png"
import Pathology from "../../../assets/Pathology Reports.png";
import Hospital from "../../../assets/Hospital Records.png";
import Medical from "../../../assets/Medical Images.png";
import Vaccination from "../../../assets/Vaccination.png";
import * as utils from "../../../Utils";
import "./History.css";
import { Link, useNavigate } from "react-router-dom";
import { useApp } from "./../../../AppProvider";

const options = [
  { value: "PathologyRecord", label: "PathologyRecord" },
  { value: "RadiologyRecord", label: "RadiologyRecord" },
  { value: "VaccinationRecord", label: "VaccinationRecord" },
  { value: "PrescriptionRecord", label: "PrescriptionRecord" },
  { value: "HospitalizationRecord", label: "HospitalizationRecord" },
];

function History() {
  const navigate = useNavigate();
  const { patientsMHR } = useApp();
  const [filteredData, setFilteredData] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [condition, setCondition] = useState("");
  const datePickerRef = useRef();
  const { setBoardDate } = useApp();

  useEffect(() => {
    let filtered = patientsMHR;
    if (condition) {
      filtered = filtered.filter((record) => record.typeOfRecord === condition);
    }

    if (startDate && endDate) {
      filtered = filtered.filter((record) => {
        const recordDate = new Date(record.dateOfPublish);
        return recordDate >= startDate && recordDate <= endDate;
      });
    }

    filtered = filtered.sort((a, b) => new Date(b.dateOfPublish) - new Date(a.dateOfPublish));

    setFilteredData(filtered);
  }, [condition, patientsMHR, startDate, endDate]);

  const handleDateClick = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true);
    }
  };

  const handleDateChange = (start, end) => {
    setStartDate(new Date(start));
    setEndDate(new Date(end));
    setBoardDate(new Date(start).toISOString().split("T")[0]);
  };

  const handleClearDates = () => {
    setStartDate(null);
    setEndDate(null);
  };

  const RecordCard = ({ record }) => {
    const logos = {
      PrescriptionRecord: Prescription,
      PathologyRecord: Pathology,
      RadiologyRecord: Medical,
      VaccinationRecord: Vaccination,
      HospitalizationRecord: Hospital,
    };
    const logo = logos[record.typeOfRecord] || Pathology;
    return (
      <Link
        className="card_container"
        to={`/DashboardDashboardNavigation/records/${record.recordId}`}
      >
        <div className="inner-card">
          <div className="icon">
            <img className="icon-logo" src={logo} alt="logo" />
            <div className="date-tag">
              <span>{utils.formatDate(record.dateOfPublish)}</span>
            </div>
          </div>
          <div className="card-detail">
            <h4>{record.typeOfRecord}</h4>
            <div className="card-title">
              <ul className="ul">
                {record.testValues &&
                  record.testValues
                    .filter((item) => item.classification === "Abnormal")
                    .slice(0, 3)
                    .map((item, index) => (
                      <li key={index}>
                        {item.testName}:{" "}
                        <span className="detail1">{item.value}</span>
                      </li>
                    ))}
              </ul>
            </div>
          </div>
        </div>
      </Link>
    );
  };

  return (
    <div className="history-details">
      <div className="history-dates">
        <Dropdown
          controlClassName="my-dropdown-control"
          options={options}
          onChange={(option) => setCondition(option.value)}
          value={condition}
          placeholder="Select Condition"
        />
        <div className="select-date" onClick={handleDateClick}>
          <label>
            {startDate && endDate
              ? `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`
              : "Select Date Range"}
          </label>

          <div className="date-picker-container">
            <DateRangePicker
              className="date-picker"
              startDate={startDate}
              endDate={endDate}
              onApply={(event, picker) =>
                handleDateChange(picker.startDate, picker.endDate)
              }
              onCancel={handleClearDates}
              opens="left"
              autoApply={true}
              autoUpdateInput={false}
              linkedCalendars={true}
              showCustomRangeLabel={false}
              ranges={{
                Today: [new Date(), new Date()],
                "This Month": [new Date(), new Date()],
              }}
              locale={{ cancelLabel: "Clear" }}
              cancelButtonClasses="btn btn-light" 
              cancelLabel="Clear Dates" 
            >
              <img className="icon" src={CalendarIcon} alt="" />
            </DateRangePicker>
          </div>
        </div>
      </div>
      <div className="history-cards">
        {filteredData.length > 0 ? (
          filteredData.map((record, index) => (
            <RecordCard key={index} record={record} />
          ))
        ) : (
          <div className="dataNotAvil">History Data Not Available yet!</div>
        )}
      </div>
    </div>
  );
}

export default History;
