import React from 'react';
import { FaCalendarAlt } from 'react-icons/fa';
import PropTypes from 'prop-types';

const CustomCalendar = React.forwardRef(({ value, onClick }, ref) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      border: '1px solid #ccc',
      padding: '10px 15px',
      borderRadius: '5px',
      cursor: 'pointer',
    }}
    onClick={onClick}
    role="button"
    tabIndex={0}
    onKeyDown={(e) => e.key === 'Enter' && onClick()}
  >
    <FaCalendarAlt style={{ marginRight: '8px', color: '#000' }} />
    <input
      style={{
        border: 'none',
        outline: 'none',
        cursor: 'pointer',
        backgroundColor: 'transparent',
      }}
      value={value}
      onClick={onClick}
      readOnly
      ref={ref}
      aria-label="Select date"
    />
  </div>
));

CustomCalendar.propTypes = {
  value: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default CustomCalendar;
