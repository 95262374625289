import React, { useState } from 'react';
import './DoctorClinic.css';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import StarRateIcon from '@mui/icons-material/StarRate';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import AddNewClinicPopup from './AddNewClinicPopup';
import { useApp } from '../../../AppProvider';


const DoctorClinic = () => {
    const { clinics } = useApp();
    const [clinicData, setClinicData] = useState();
    const [addNewClinic, setAddNewClinic] = useState(false);

    // console.log("CLINIC DATA == ", clinics)


    const handleAddNewClinic = (newClinic) => {
        setClinicData([...clinicData, { ...newClinic, id: clinicData.length + 1 }]);
        setAddNewClinic(false);
    };

    const renderStars = (rating) => {
        const fullStars = Math.floor(rating);
        const halfStar = rating % 1 !== 0;
        const stars = [];

        for (let i = 0; i < fullStars; i++) {
            stars.push(<StarRateIcon key={i} className="star_icon" />);
        }
        if (halfStar) {
            stars.push(<StarHalfIcon key="half" className="star_icon" />);
        }
        return stars;
    };

    return (
        <div className="doctor_clinic-main_container">
            <div className="doctorClinicContainer">
                <Link className="doctorClinicBackLink" to="/setting">
                    <ArrowBackIosIcon />
                    <div>Go Back</div>
                </Link>
                <div className="doctorClinic-add-new_clinic-button"
                    onClick={() => { setAddNewClinic(true) }} >
                    <AddIcon className="addIconNewClinic" />
                    <div>Add New Clinic</div>
                </div>
            </div>
            <div className="clinic-card_main-container">
                {clinics.map((item, index) => (
                    <Link key={index} className="clinic-card-container" to={{
                        pathname: `/setting/doctorclinic/clinicdetailscard/${item.clinicId}`, state: { clinic: item }
                    }}>
                        <div className="left-box">
                            <img src='https://th.bing.com/th/id/R.d4b16e5075153dd5dc2db9c479239359?rik=ci7gLX%2fbowmoXQ&riu=http%3a%2f%2fihg.scene7.com%2fis%2fimage%2fihg%2feven-hotels-eugene-5405616297-4x3&ehk=16ED5J2C4YgE5BpmSJ6%2f4n11Nxewe3ot%2fBrksi8s%2fdQ%3d&risl=&pid=ImgRaw&r=0' alt="img" />
                        </div>
                        <div className="right-box">
                            <div className="clinic-name">{item.name.fullName}</div>
                            <div className="doctor-name">{item.opratingTime}</div>
                            <div className="location-section">
                                <LocationOnIcon className="location-icon" />
                                <div className="clinic_location">{item.contact.address[0].addressLine1}</div>
                            </div>
                            <div className="rating-section">
                                {renderStars(item.rating)}
                                <div className="rating-count">{item.rating}</div>
                            </div>
                        </div>
                    </Link>
                ))}
            </div>
            {addNewClinic && (
                <AddNewClinicPopup setAddNewClinic={setAddNewClinic} handleAddNewClinic={handleAddNewClinic} />
            )}
        </div>
    );
};

export default DoctorClinic;