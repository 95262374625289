import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import './AddNewClinicPopup.css';
import CloseIcon from '@mui/icons-material/Close';
import { useAuth } from '../../../AuthProvider';
import { useApp } from '../../../AppProvider';

const specializationsOptions = [
  { value: 'Cardio', label: 'Cardio' },
  { value: 'Eye', label: 'Eye' },
  { value: 'Pediatric', label: 'Pediatric' },
  { value: 'Dental', label: 'Dental' },
  { value: 'Orthopedic', label: 'Orthopedic' }
];

const cityOptions = [
  { value: 'City 1', label: 'City 1' },
  { value: 'City 2', label: 'City 2' },
  { value: 'City 3', label: 'City 3' },
];

const stateOptions = [
  { value: 'State 1', label: 'State 1' },
  { value: 'State 2', label: 'State 2' },
  { value: 'State 3', label: 'State 3' },
];

const countryOptions = [
  { value: 'Country 1', label: 'Country 1' },
  { value: 'Country 2', label: 'Country 2' },
  { value: 'Country 3', label: 'Country 3' },
];

const AddNewClinicPopup = ({ setAddNewClinic, handleAddNewClinic }) => {
  const { accessToken } = useAuth();
  const { clinics } = useApp();
  const [name, setName] = useState("");
  const [regNumber, setRegNumber] = useState("");
  const [clinicAddress, setClinicAddress] = useState("");
  const [mapsLink, setMapsLink] = useState("");
  const [googleLink, setGoogleLink] = useState("");
  const [about, setAbout] = useState("");
  const [specializations, setSpecializations] = useState([]);
  const [logo, setLogo] = useState(null);
  const [opratingTime, setOpratingTime] = useState([{ start: "", end: "", days: [] }]);
  const [contact, setContact] = useState("");
  const [pincode, setPincode] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
 
  useEffect(() => {
    document.body.classList.add('no-scroll');
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, []);

  console.log("clinics=", clinics)

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("name", name.fullName);
    // formData.append("aliasName", name.aliasName);
    formData.append("contact", JSON.stringify(contact));
    // formData.append("geoCoordinates", JSON.stringify(geoCoordinates));
    formData.append("opratingTime", opratingTime);
    // formData.append("regNumber", regNumber);
    // formData.append("clinicAddress", clinicAddress);
    // formData.append("mapsLink", mapsLink);
    // formData.append("googleLink", googleLink);
    // formData.append("about", about);
    // formData.append("specializations", JSON.stringify(specializations.map(spec => spec.value)));
    // formData.append("pincode", pincode);
    // formData.append("city", city);
    // formData.append("state", state);
    // formData.append("country", country);
    // formData.append("landmark", landmark);
    if (logo) {
      formData.append("logo", logo);
    }

    try {
      const id = clinics._id
      console.log("clinics ID", clinics._id)
      const response = await fetch(`https://dev3.backend.cohesivehealth.in/clinics/${id}`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${accessToken}`
        },
        body: formData,
      });
      if (response.ok) {
        const data = await response.json();
        handleAddNewClinic(data);
        setAddNewClinic(false);
      } else {
        console.error("Failed to add clinic", response.statusText);
      }
    } catch (error) {
      console.error("Error adding clinic:", error);
    }
  };

  const handleTimeSlotChange = (index, field, value) => {
    const newTimeSlots = [...opratingTime];
    newTimeSlots[index][field] = value;
    setOpratingTime(newTimeSlots);
  };

  const addTimeSlot = () => {
    setOpratingTime([...opratingTime, { start: "", end: "", days: [] }]);
  };

  const removeTimeSlot = (index) => {
    const newTimeSlots = opratingTime.filter((_, i) => i !== index);
    setOpratingTime(newTimeSlots);
  };

  return (
    <div className="clinic_overlay_popup-container">
      <div className="clinic_pop_popup-content">
        <div className="clinic_pop_popup-header">
          <h2>Add New Clinic</h2>
          <CloseIcon className="clinic_pop_close-icon" onClick={() => setAddNewClinic(false)} />
        </div>
        {/* <hr /> */}
        <form onSubmit={handleSubmit} className="clinic_pop_popup-form">
          <label>
            Clinic Name:
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </label>
          <label>
            Registration Number:
            <input
              type="text"
              value={regNumber}
              onChange={(e) => setRegNumber(e.target.value)}
              required
            />
          </label>
          <label>
            Contact Number:
            <input
              type="text"
              value={contact}
              onChange={(e) => setContact(e.target.value)}
              required
            />
          </label>
          <label>
            Pincode:
            <input
              type="text"
              value={pincode}
              onChange={(e) => setPincode(e.target.value)}
              required
            />
          </label>
          <label>
            City:
            <Select
              options={cityOptions}
              placeholder="Select City"
              value={cityOptions.find(option => option.value === city)}
              onChange={(selectedCity) => setCity(selectedCity.value)}
            />
          </label>
          <label>
            State:
            <Select
              options={stateOptions}
              placeholder="Select State"
              value={stateOptions.find(option => option.value === state)}
              onChange={(selectedState) => setState(selectedState.value)}
            />
          </label>
          <label>
            Country:
            <Select
              options={countryOptions}
              placeholder="Select Country"
              value={countryOptions.find(option => option.value === country)}
              onChange={(selectedCountry) => setCountry(selectedCountry.value)}
            />
          </label>
          <label>
            Complete Address with Landmark:
            <input
              type="text"
              value={clinicAddress}
              onChange={(e) => setClinicAddress(e.target.value)}
              required
            />
          </label>
          <label>
            Specializations:
            <Select
              options={specializationsOptions}
              isMulti
              placeholder="Select Specializations"
              value={specializations}
              onChange={(selectedOptions) => setSpecializations(selectedOptions)}
            />
          </label>
          <label>
            About:
            <textarea
              value={about}
              onChange={(e) => setAbout(e.target.value)}
              required
            />
          </label>
          <h3>Select Time Slots</h3>
          {opratingTime.map((slot, index) => (
            <div key={index}>
              <h6>Slot {index + 1}</h6>
              <div className="time-slot">
                <input
                  type="time"
                  value={slot.start}
                  onChange={(e) => handleTimeSlotChange(index, 'start', e.target.value)}
                  required
                />
                <input
                  type="time"
                  value={slot.end}
                  onChange={(e) => handleTimeSlotChange(index, 'end', e.target.value)}
                  required
                />
              </div>
              <Select
                options={[
                  { value: 'Monday', label: 'Monday' },
                  { value: 'Tuesday', label: 'Tuesday' },
                  { value: 'Wednesday', label: 'Wednesday' },
                  { value: 'Thursday', label: 'Thursday' },
                  { value: 'Friday', label: 'Friday' },
                  { value: 'Saturday', label: 'Saturday' },
                  { value: 'Sunday', label: 'Sunday' },
                ]}
                isMulti
                placeholder="Select Days"
                value={slot.days}
                onChange={(selectedDays) => handleTimeSlotChange(index, 'days', selectedDays)}
              />
              {index > 0 && (
                <button
                  type="button"
                  className="clinic-signup-remove-time-slot-button"
                  onClick={() => removeTimeSlot(index)}
                >
                  Remove Time Slot
                </button>
              )}
            </div>
          ))}
          <button
            type="button"
            className="clinic-signup-add-time-slot-button"
            onClick={addTimeSlot}
          >
            Add Time Slot
          </button>
          <label>
            Maps Link:
            <input
              type="text"
              value={mapsLink}
              onChange={(e) => setMapsLink(e.target.value)}
              required
            />
          </label>
          <label>
            Google Link:
            <input
              type="text"
              value={googleLink}
              onChange={(e) => setGoogleLink(e.target.value)}
              required
            />
          </label>
          <label>
            Logo:
            <input
              type="file"
              onChange={(e) => setLogo(e.target.files[0])}
            />
          </label>
          <button type="submit" className="clinic_pop_popup-button">
            Add Clinic
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddNewClinicPopup;
