import React from 'react';
import './Home.css'
import Card from './Card';
import { Droppable } from 'react-beautiful-dnd';

const Column = ({ column, tasks }) => {
  return (
    <div className={`column ${column.title.toLowerCase()}`}>
      <div className="column-header">
        {column.title}
      </div>
      <Droppable droppableId={column.id}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className={`column-content ${snapshot.isDraggingOver ? 'is-dragging-over' : ''}`}
          >
            {/* ... cards go here */}
            {tasks.map((task, index) => (
              <Card key={task.id} task={task} index={index} />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>

  );
};

export default Column;
