// DoctorNotes.js
import React, { useState, useRef, useEffect } from "react";
import Select from "react-select";
import "./DoctorNotes.css";
import DeleteIcon from "../../Setting/SettingsAssets/Delete.svg";
import { useApp } from "../../../AppProvider";
import { useAuth } from "../../../AuthProvider";

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const DoctorNotes = () => {
  const pdfRef = useRef();
  const { setPastSidebarToggle, mediTableContent, setMediTableContent, addMedicineSelecteGroupsAdvice, selectedPatientObj, selectedClinic, selectedAppointment, mediTableContentLabTest, setMediTableContentLabTest } = useApp();
  const { user, accessToken } = useAuth()
  const [menuPortalTarget, setMenuPortalTarget] = useState(null);
  const [activeTab, setActiveTab] = useState('Patient-info');
  const [symptomCards, setSymptomCards] = useState([]);
  const [diagnosisCards, setDiagnosisCards] = useState([]);
  const [rememberCards, setRememberCards] = useState([]);
  const [advice, setAdvice] = useState([]);
  const [medicationAdvice, setMedicationAdvice] = useState([]);
  const [activeSection, setActiveSection] = useState(null);
  const [symptomInput, setSymptomInput] = useState('');
  const [diagnosisInput, setDiagnosisInput] = useState('');
  const [rememberInput, setRememberInput] = useState('');
  const [appointmentInput, setAppointmentInput] = useState('');
  const [isLoading, setIsLoading] = useState(false); // State for loader


  const [symptomOptions] = useState([
    { value: "fever", label: "Fever" },
    { value: "cough", label: "Cough" },
    { value: "headache", label: "Headache" },
    { value: "nausea", label: "Nausea" },
    { value: "fatigue", label: "Fatigue" },
    { value: "sore-throat", label: "Sore Throat" },
    { value: "shortness-of-breath", label: "Shortness of Breath" },
    { value: "loss-of-taste", label: "Loss of Taste" },
    { value: "loss-of-smell", label: "Loss of Smell" },
    { value: "muscle-aches", label: "Muscle Aches" },
  ]);

  const [diagnosisOptions] = useState([
    { value: "flu", label: "Flu" },
    { value: "covid-19", label: "COVID-19" },
    { value: "migraine", label: "Migraine" },
    { value: "hypertension", label: "Hypertension" },
    { value: "diabetes", label: "Diabetes" },
  ]);

  const [rememberOptions] = useState([
    { value: "hydrate", label: "Stay Hydrated" },
    { value: "rest", label: "Get Plenty of Rest" },
    { value: "exercise", label: "Regular Exercise" },
    { value: "medication", label: "Take Medication on Time" },
    { value: "followup", label: "Schedule Follow-up" },
  ]);

  const [adviceOptions] = useState([
    { value: "bloodwork", label: "Blood Work" },
    { value: "xray", label: "X-Ray" },
    { value: "checkup", label: "Regular Checkup" },
    { value: "vaccination", label: "Vaccination" },
    { value: "therapy", label: "Therapy Session" },
  ]);

  useEffect(() => {
    const savedSymptoms = JSON.parse(localStorage.getItem("symptomCards"));
    const savedDiagnosis = JSON.parse(localStorage.getItem("diagnosisCards"));
    const savedRemember = JSON.parse(localStorage.getItem("rememberCards"));
    const saveAdvice = JSON.parse(localStorage.getItem("advice"));
    const saveMedicationAdvice = JSON.parse(localStorage.getItem("medicationAdvice"));

    if (savedSymptoms) setSymptomCards(savedSymptoms);
    if (savedDiagnosis) setDiagnosisCards(savedDiagnosis);
    if (savedRemember) setRememberCards(savedRemember);
    if (saveAdvice) setAdvice(saveAdvice);
    if (saveMedicationAdvice) setAdvice(saveMedicationAdvice);
  }, []);

  useEffect(() => {
    localStorage.setItem("symptomCards", JSON.stringify(symptomCards));
    localStorage.setItem("diagnosisCards", JSON.stringify(diagnosisCards));
    localStorage.setItem("rememberCards", JSON.stringify(rememberCards));
    localStorage.setItem("advice", JSON.stringify(advice));
    localStorage.setItem("medicationAdvice", JSON.stringify(medicationAdvice));
  }, [symptomCards, diagnosisCards, rememberCards, advice, medicationAdvice]);

  const handleDeleteMedicine = (serialNo) => {
    const updatedMediTableContent = mediTableContent
      .filter((item) => item.serialNo !== serialNo)
      .map((item, index) => ({ ...item, serialNo: (index + 1).toString() }));
    setMediTableContent(updatedMediTableContent);
  };

  const handleDeleteTest = (serialNo) => {
    const updatedTestMediTableContent = mediTableContentLabTest
      .filter((item) => item.serialNo !== serialNo)
      .map((item, index) => ({ ...item, serialNo: (index + 1).toString() }));
    setMediTableContentLabTest(updatedTestMediTableContent);
  }

  const handleClickPatientInfo = () => {
    setPastSidebarToggle(false);
    setActiveTab('Patient-info');
    setSymptomCards([]);
    setDiagnosisCards([]);
    setRememberCards([]);
    setAdvice([]);
    setMedicationAdvice([]);
  };

  const handleClickPastRecords = () => {
    setPastSidebarToggle(true);
    setActiveTab('Past-records');
    setSymptomCards([]);
    setDiagnosisCards([]);
    setRememberCards([]);
    setAdvice([]);
    setMedicationAdvice([]);

  };

  const handleSymptomChange = (selectedOptions) => {
    const newCards = selectedOptions.map(option => ({
      value: option.value,
      label: option.label
    }));
    setSymptomCards(prevCards => [...prevCards, ...newCards]);
  };

  const handleDiagnosisChange = (selectedOptions) => {
    const newCards = selectedOptions.map(option => ({
      value: option.value,
      label: option.label
    }));
    setDiagnosisCards(prevCards => [...prevCards, ...newCards]);
  };

  const handleRememberChange = (selectedOptions) => {
    const newCards = selectedOptions.map(option => ({
      value: option.value,
      label: option.label
    }));
    setRememberCards(prevCards => [...prevCards, ...newCards]);
  };

  const handleAdviceChange = (selectedOptions) => {
    const newCards = selectedOptions.map(option => ({
      value: option.value,
      label: option.label
    }));
    setAdvice(prevCards => [...prevCards, ...newCards]);
    setMedicationAdvice(prevCards => [...prevCards, ...newCards]);
  };

  const handleSymptomInputKeyDown = (event) => {
    if (event.key === 'Enter' && symptomInput) {
      const newCard = { value: symptomInput, label: symptomInput };
      setSymptomCards(prevCards => [...prevCards, newCard]);
      setSymptomInput('');
    }
  };

  const handleDiagnosisInputKeyDown = (event) => {
    if (event.key === 'Enter' && diagnosisInput) {
      const newCard = { value: diagnosisInput, label: diagnosisInput };
      setDiagnosisCards(prevCards => [...prevCards, newCard]);
      setDiagnosisInput('');
    }
  };

  const handleRememberInputKeyDown = (event) => {
    if (event.key === 'Enter' && rememberInput) {
      const newCard = { value: rememberInput, label: rememberInput };
      setRememberCards(prevCards => [...prevCards, newCard]);
      setRememberInput('');
    }
  };

  const handleAppointmentInputKeyDown = (event) => {
    if (event.key === 'Enter' && appointmentInput) {
      const newCard = { value: appointmentInput, label: appointmentInput };
      setAdvice(prevCards => [...prevCards, newCard]);
      setAppointmentInput('');
    }
  };

  const removeSymptomCard = (index) => {
    const newCards = [...symptomCards];
    newCards.splice(index, 1);
    setSymptomCards(newCards);
  };

  const removeDiagnosisCard = (index) => {
    const newCards = [...diagnosisCards];
    newCards.splice(index, 1);
    setDiagnosisCards(newCards);
  };

  const removeRememberCard = (index) => {
    const newCards = [...rememberCards];
    newCards.splice(index, 1);
    setRememberCards(newCards);
  };

  // const removeAdviceCard1 = (index) => {
  //   const newCards = [...advice];
  //   newCards.splice(index, 1);
  //   setAdvice(newCards);
  // };
  const removeAdviceCard = (index) => {
    const newAdvice = [...advice];
    const newMedicationAdvice = [...medicationAdvice];

    newAdvice.splice(index, 1);
    newMedicationAdvice.splice(index, 1);

    setAdvice(newAdvice);
    setMedicationAdvice(newMedicationAdvice);
  };

  const removeMedicationAdviceCard = (index) => {
    const newMedicationAdvice = [...medicationAdvice];
    newMedicationAdvice.splice(index, 1);
    setMedicationAdvice(newMedicationAdvice);
  };

  const renderSymptomCards = () => (
    symptomCards.map((symptom, index) => (
      <div key={index} className="symptom-card">
        {symptom.label}
        <span className="card-remove" onClick={() => removeSymptomCard(index)}>x</span>
      </div>
    ))
  );

  const renderDiagnosisCards = () => (
    diagnosisCards.map((diagnosis, index) => (
      <div key={index} className="diagnosis-card">
        {diagnosis.label}
        <span className="card-remove" onClick={() => removeDiagnosisCard(index)}>x</span>
      </div>
    ))
  );

  const renderRememberCards = () => (
    rememberCards.map((remember, index) => (
      <div key={index} className="remember-card">
        {remember.label}
        <span className="card-remove" onClick={() => removeRememberCard(index)}>x</span>
      </div>
    ))
  );

  const renderAdvice = () => (
    advice.map((itemAdvice, index) => (
      <>
        <div key={index} className="appointment-card">
          {itemAdvice.label}
          <span className="card-remove" onClick={() => removeAdviceCard(index)}>x</span>
        </div>
        {addMedicineSelecteGroupsAdvice ? (
          <div key={`${index}-medicine`} className="appointment-card">
            {addMedicineSelecteGroupsAdvice}
            <span className="card-remove" onClick={() => removeMedicationAdviceCard(index)}>x</span>
          </div>
        ) : null}
      </>
    ))
  );

  const handleInputChange = (serialNo, columnKey, value) => {
    const updatedContent = mediTableContent.map((item) => {
      if (item.serialNo === serialNo) {
        return { ...item, [columnKey]: value };
      }
      return item;
    });
    setMediTableContent(updatedContent);
  };

  const createPdf = async () => {
    setIsLoading(true); // Show loader
    const url = "https://dev.gateway.cohesivehealth.in/mhr/issuePrescription";
    const dobDate = new Date(selectedPatientObj.dateOfBirth);
    const today = new Date();
    let age = today.getFullYear() - dobDate.getFullYear();
    // Capture the lab test details
    const labTests = mediTableContentLabTest.map(test => ({
      testName: test.testName,
      department: test.department
    }));
    const body = {
      medications: mediTableContent,
      name: `${selectedPatientObj.name.firstName} ${selectedPatientObj.name.lastName} `,
      gender: `${selectedPatientObj.gender}`,
      age: age,
      weight: `${selectedPatientObj.weight}`,
      bloodGroup: `${selectedPatientObj.bloodGroup}`,
      temperature: '',
      allergies: `${selectedPatientObj.allergies}`,
      symptoms: `${symptomCards.map(item => item.value).join(', ')}`,
      diagnosis: `${diagnosisCards.map(item => item.value).join(', ')}`,
      tests: labTests,
      advice: `${addMedicineSelecteGroupsAdvice}`,
      medicalCondition: `${selectedPatientObj.chronicConditions}`,
      doctorName: `${user.name.firstName} ${user.name.lastName} `,
      qualification: user.specilizations[0],
      registrationNumber: 'REG12897987',
      phoneNumber: selectedClinic.contact.phoneNumber.phoneNumber,
      clinicAdd: `${selectedClinic.name.fullName},\n${selectedClinic.contact.address[0].addressLine}`,
      patientId: selectedPatientObj._id,
      clinicId: selectedClinic._id,
      appointmentId: selectedAppointment,
      dateOfPublish: today,
      typeOfRecord: "PrescriptionRecord",
      doctorsNote: rememberInput,
      doctorId: user._id,
    }
    console.log(JSON.stringify(body))
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${accessToken}`
      },
      body: JSON.stringify(body)
    };
    try {
      const response = await fetch(url, requestOptions);
      if (!response.ok) {
        throw new Error(`Error Generating Prescription1: ${response.status}`);
      }
      console.log('REPO', response)
      const blob = await response.blob();
      const pdfUrl = URL.createObjectURL(blob);
      window.open(pdfUrl, '_blank');
    } catch (error) {
      alert(`Error Generating Prescription: ${error.message}`);
    } finally {
      setIsLoading(false); // Hide loader
    }
  };

  return (
    <div className="doctor-notes" ref={pdfRef}>
      <div className="container_toggle-main">
        <div className="toggle-box">
          <div
            onClick={handleClickPatientInfo}
            className={`Patient-info ${activeTab === 'Patient-info' ? 'active' : ''}`}
          >
            Patient info
          </div>
          <div
            onClick={handleClickPastRecords}
            className={`Past-records ${activeTab === 'Past-records' ? 'active' : ''}`}
          >
            Past records
          </div>
        </div>
      </div>
      <div className="input-main-container">
        <div>
          <label>Temperature</label>
          <input type="text" className="input-main-container-text" />
        </div>
        <div>
          <label>Blood Pressure</label>
          <input type="text" className="input-main-container-text" />
        </div>
        <div>
          <label>Weight</label>
          <input type="text" className="input-main-container-text" />
        </div>
      </div>
      <div className="doctor-notes-column-1">
        <div className="cards-section">
          <div
            className={`doctor-cards doctor-card-text ${activeSection === 'symptoms' ? 'active' : ''}`}
            onClick={() => setActiveSection('symptoms')}
          >
            <Select
              isMulti
              placeholder="Symptoms"
              value={[]}
              onChange={handleSymptomChange}
              inputValue={symptomInput}
              onInputChange={(input, { action }) => {
                if (action === 'input-change') setSymptomInput(input);
              }}
              onKeyDown={handleSymptomInputKeyDown}
              options={symptomOptions}
              classNamePrefix="react-select"
              className="react-select-container"
              menuPortalTarget={menuPortalTarget}
              styles={{
                menu: (base) => ({ ...base, height: 150 }),
                menuList: (base) => ({ ...base, maxHeight: 150 }),
              }}
            />
            <div className="cards-container">
              {renderSymptomCards()}
            </div>
          </div>
        </div>
        <div className="cards-section">
          <div
            className={`doctor-cards doctor-card-text ${activeSection === 'diagnosis' ? 'active' : ''}`}
            onClick={() => setActiveSection('diagnosis')}
          >
            <Select
              isMulti
              placeholder="Diagnosis"
              value={[]}
              onChange={handleDiagnosisChange}
              inputValue={diagnosisInput}
              onInputChange={(input, { action }) => {
                if (action === 'input-change') setDiagnosisInput(input);
              }}
              onKeyDown={handleDiagnosisInputKeyDown}
              options={diagnosisOptions}
              classNamePrefix="react-select"
              className="react-select-container"
              menuPortalTarget={menuPortalTarget}
              styles={{
                menu: (base) => ({ ...base, height: 150 }),
                menuList: (base) => ({ ...base, maxHeight: 150 }),
              }}
            />
            <div className="cards-container">
              {renderDiagnosisCards()}
            </div>
          </div>
        </div>
      </div>
      <div className="doctor-notes-column-2">
        <div className="cards-section">
          <div
            className={`doctor-cards doctor-card-text ${activeSection === 'appointment' ? 'active' : ''}`}
            onClick={() => setActiveSection('appointment')}
          >
            <Select
              isMulti
              placeholder="Advice"
              value={[]}
              onChange={handleAdviceChange}
              inputValue={appointmentInput}
              onInputChange={(input, { action }) => {
                if (action === 'input-change') setAppointmentInput(input);
              }}
              onKeyDown={handleAppointmentInputKeyDown}
              options={adviceOptions}
              classNamePrefix="react-select"
              className="react-select-container"
              menuPortalTarget={menuPortalTarget}
              styles={{
                menu: (base) => ({ ...base, height: 150 }),
                menuList: (base) => ({ ...base, maxHeight: 150 }),
              }}
            />
            <div className="cards-container">
              {renderAdvice()}
            </div>
          </div>
        </div>
        <div className="cards-section">
          <div
            className={`doctor-cards doctor-card-text ${activeSection === 'remember' ? 'active' : ''}`}
            onClick={() => setActiveSection('remember')}
          >
            <Select
              isMulti
              placeholder="Things to remember"
              value={[]}
              onChange={handleRememberChange}
              inputValue={rememberInput}
              onInputChange={(input, { action }) => {
                if (action === 'input-change') setRememberInput(input);
              }}
              onKeyDown={handleRememberInputKeyDown}
              options={rememberOptions}
              classNamePrefix="react-select"
              className="react-select-container"
              menuPortalTarget={menuPortalTarget}
              styles={{
                menu: (base) => ({ ...base, height: 150 }),
                menuList: (base) => ({ ...base, maxHeight: 150 }),
              }}
            />
            <div className="cards-container">
              {renderRememberCards()}
            </div>
          </div>
        </div>
      </div>
      <h2 className="cards-heading-medicine">Medicines</h2>
      <div className="medi-table-container-notes">
        <div className="medi-table-content">
          <table className="medi-table">
            <thead>
              <tr className="medi-table-header">
                <th className="medi-header-text">S.No.</th>
                <th className="medi-header-text">Brand Name</th>
                <th className="medi-header-text">Drug Name</th>
                <th className="medi-header-text">Quantity</th>
                <th className="medi-header-text">Duration</th>
                <th className="medi-header-text">Dosage</th>
                <th className="medi-header-text">Remarks</th>
                <th className="medi-header-text">Delete</th>
              </tr>
            </thead>
            <tbody>
              {mediTableContent.length > 0 ? (
                mediTableContent.map((group) => (
                  <tr className="medi-table-body" key={group.serialNo}>
                    <td className="medi-table-el" style={{ textAlign: "center" }}>{group.serialNo}</td>
                    <td className="medi-table-el">{group.brand}</td>
                    <td className="medi-table-el">{group.drugsName}</td>
                    <td className="medi-table-el">
                      <input
                        type="text"
                        placeholder="Enter quantity"
                        value={group.quantity}
                        onChange={(e) => handleInputChange(group.serialNo, 'quantity', e.target.value)}
                        className="medicine-table-input"
                        list={`quantity-options-${group.serialNo}`}
                        style={{ fontSize: '15px' }}
                      />
                      <datalist id={`quantity-options-${group.serialNo}`}>
                        <option value="1 tablet"></option>
                        <option value="2 tablet"></option>
                        <option value="3 tablet"></option>
                        <option value="4 tablet"></option>
                        <option value="5 tablet"></option>
                      </datalist>
                    </td>
                    <td className="medi-table-el">
                      <input
                        type="text"
                        placeholder="Enter duration"
                        value={group.duration}
                        onChange={(e) => handleInputChange(group.serialNo, 'duration', e.target.value)}
                        className="medicine-table-input"
                        list={`duration-options-${group.serialNo}`}
                        style={{ fontSize: '15px' }}
                      />
                      <datalist id={`duration-options-${group.serialNo}`}>
                        <option value="1 day"></option>
                        <option value="3 days"></option>
                        <option value="1 week"></option>
                        <option value="2 weeks"></option>
                        <option value="1 month"></option>
                      </datalist>
                    </td>
                    <td className="medi-table-el">
                      <input
                        type="text"
                        placeholder="Enter dosage"
                        value={group.dosage}
                        onChange={(e) => handleInputChange(group.serialNo, 'dosage', e.target.value)}
                        className="medicine-table-input"
                        list={`dosage-options-${group.serialNo}`}
                        style={{ fontSize: '15px' }}
                      />
                      <datalist id={`dosage-options-${group.serialNo}`}>
                        <option value="Once a day"></option>
                        <option value="Twice a day"></option>
                        <option value="Thrice a day"></option>
                        <option value="As needed"></option>
                      </datalist>
                    </td>
                    <td className="medi-table-el">
                      <input
                        type="text"
                        placeholder="Enter remarks"
                        value={group.remarks}
                        onChange={(e) => handleInputChange(group.serialNo, 'remarks', e.target.value)}
                        className="medicine-table-input"
                        style={{ fontSize: '15px' }}
                      />
                    </td>
                    <td className="medi-table-el">
                      <img
                        className="medi-delete-icon"
                        src={DeleteIcon}
                        alt="deleteicon"
                        onClick={() => handleDeleteMedicine(group.serialNo)}
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="8" className="medi-no-medicine-selected">
                    No Medicine Selected
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <h2 className="cards-heading-test">Tests</h2>
      <div className="test-table-container-notes">
        <div className="test-table-content-notes">
          <table className="test-table">
            <thead>
              <tr className="test-thead-lab">
                <th className="test-header-text serial-tests-th">S.No.</th>
                <th className="test-header-text">Test Name</th>
                <th className="test-header-text">Lab Name</th>
                <th className="test-header-text delete-tests-th">Delete</th>
              </tr>
            </thead>
            <tbody>
              {mediTableContentLabTest.length > 0 ? (
                mediTableContentLabTest.map((test) => (
                  <tr className="test-table-body" key={test.serialNo}>
                    <td className="test-table-el" style={{ textAlign: "center" }}>{test.serialNo}</td>
                    <td className="test-table-el">{test.testName}</td>
                    <td className="test-table-el">{test.department}</td>
                    <td className="test-table-el">
                      <img
                        className="test-delete-icon"
                        src={DeleteIcon}
                        alt="deleteicon"
                        onClick={() => handleDeleteTest(test.serialNo)}
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4" className="test-no-tests-selected">
                    No Tests Selected
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {/* <div> */}
      <div className="div-btn-down">
        <button className="down-btn" onClick={createPdf}>Issue Prescription</button>
      </div>

      {isLoading && ( // Render loader when isLoading is true
        <div className="popup-overlay">
          <div className="popup-content">
            <p>Loading...</p>
          </div>
        </div>
      )}
    </div>
    // </div>
  );
};

export default DoctorNotes;
