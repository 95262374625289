import React, { useState, useEffect } from "react";
import "./ManageMedicineCards.css";
import { Link } from "react-router-dom";

const ManageMedicineCards = ({ medicineData, MediRecordId }) => {
  const [activeRecordId, setActiveRecordId] = useState(MediRecordId);

  useEffect(() => {
    setActiveRecordId(MediRecordId);
  }, [MediRecordId]);

  const handleCardClick = (group) => {
    setActiveRecordId(group._id);
  };

  return (
    <div className="medi_left_list">
      {medicineData?.map((group, index) => (
        <Link
          key={group._id || index}
          to={`/setting/managemedicinegroups/${group._id}`}
          className={`medicards_groups ${activeRecordId === group._id ? "active_card" : ""}`}
          onClick={() => handleCardClick(group)}
        >
          <div className="medicards_content">
            <div className="medicards_heading">{group.groupName}</div>
            <div className="medicards_text">{group.advice}</div>
          </div>
          <div className="medication_tag-main_container">
            {group.tags.map((tag, index) => ( 
              <div
                key={index}
                className={`medicards_iconbox ${activeRecordId === group._id ? "active_card" : ""}`}
              >
                <div className="medicards_icontext">{tag}</div>
              </div>
            ))}
          </div>
        </Link>
      ))}
    </div>
  );
};

export default ManageMedicineCards;
