import React, { useState } from 'react';
import './CustomDropdown.css'; // Ensure your CSS is linked
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const CustomDropdown = ({ options, onSelect, selectedValue, placeholder = 'Select an option' }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleToggle = () => {
        setIsOpen((prev) => !prev);
    };

    const handleOptionClick = (option) => {
        onSelect(option);
        setIsOpen(false);
    };

    return (
        <div className="dropdown">
            <div className="dropdown-header" onClick={handleToggle}>
                <span className={selectedValue ? '' : 'dropdown-placeholder'}>
                    {selectedValue || placeholder}
                </span>
                <span className={`dropdown-arrow ${isOpen ? 'open' : ''}`}>
                    <ExpandMoreIcon />
                    {/* ▼ */}
                </span>
            </div>
            {isOpen && (
                <ul className="dropdown-list">
                    {options.map((option, index) => (
                        <li
                            key={index}
                            className="dropdown-item"
                            onClick={() => handleOptionClick(option)}
                        >
                            {option.label}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default CustomDropdown;
