import React, { useState, useRef } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./customCalendar.css";
import AppointmentSearchPage from "../../Components/Appointment/AppointmentSearchPage";
import { useApp } from './../../AppProvider';

const visitTypes = {
  critical: { label: "Critical", color: "#E2515E" },
  regular: { label: "Regular", color: "#FFD56D" },
  new: { label: "New", color: "#66D348" },
};

const CustomCalendar = () => {
  const modalRef = useRef();
  const { setBoardDate, isModalOpenSearchAppointment, setIsModalOpenSearchAppointment, appointments } = useApp();
  const [selectStartDate, setSelectStartDate] = useState(new Date());


  const visitsCountByDate = appointments.reduce((acc, appointment) => {
    const date = new Date(appointment.dateOfAppointment);
    const formattedDate = date.toISOString().split('T')[0];

    // Initialize the date key if not already present
    if (!acc[formattedDate]) {
      acc[formattedDate] = { "Critical": 0, "Regular": 0, "New": 0 };
    }

    // Update counts based on tags in each appointment
    appointment.tags.forEach((tag) => {
      if (acc[formattedDate][tag] !== undefined) {
        acc[formattedDate][tag]++;
      }
    });

    return acc;
  }, {});

  // Transform into the desired format
  const formattedVisits = Object.keys(visitsCountByDate).reduce((acc, date) => {
    const { Critical, Regular, New } = visitsCountByDate[date];
    acc[date] = [
      { type: "critical", count: Critical, color: "#FCE8E7" },
      { type: "regular", count: Regular, color: "#FFF5D3" },
      { type: "new", count: New, color: "#DAF7D0" }
    ];
    return acc;
  }, {});

  console.log('Formatted Counts:', formattedVisits);



  const handleDateChange = (date) => {
    const dateString = `${date.getFullYear()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
    setSelectStartDate(dateString);
    setBoardDate(dateString);
  };

  const tileContent = ({ date, view }) => {
    if (view === "month") {
      const dateString = date.toLocaleDateString("en-CA");
      const visits = formattedVisits[dateString] || [];

      return (
        <>
          {visits.map((visit, index) => {
            const { type, count } = visit;
            const { color } = visitTypes[type];
            const iconColor = type === "regular" ? "black" : "white";
            const textColor = type === "regular" ? "black" : "white";
            return (
              <div key={index} className="custom-label" style={{ backgroundColor: color }}>
                <svg className="custom-label-color" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" style={{ color: iconColor }} >
                  <path fill="currentColor" d="M12 12.25a3.75 3.75 0 1 1 3.75-3.75A3.75 3.75 0 0 1 12 12.25m0-6a2.25 2.25 0 1 0 2.25 2.25A2.25 2.25 0 0 0 12 6.25m7 13a.76.76 0 0 1-.75-.75c0-1.95-1.06-3.25-6.25-3.25s-6.25 1.3-6.25 3.25a.75.75 0 0 1-1.5 0c0-4.75 5.43-4.75 7.75-4.75s7.75 0 7.75 4.75a.76.76 0 0 1-.75.75" />
                </svg>
                <div className="custom-label-count" style={{ color: textColor }}>
                  {visitTypes[type].label}
                  <div className="count-box">{count}</div>
                </div>
              </div>
            );
          })}
        </>
      );
    }
    return null;
  };

  const tileClassName = ({ date, view }) => {
    if (view === 'month' && date.toDateString() === new Date().toDateString()) {
      return 'today-highlight';
    }
  };

  return (
    <div className="custom-div">
      <Calendar
        onChange={handleDateChange}
        selected={selectStartDate}
        tileContent={tileContent}
        tileClassName={tileClassName}
      />
      <button className="custom-button" onClick={() => { setIsModalOpenSearchAppointment(true) }}>
        <img alt="logo" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABMSURBVHgB7ZOxDQAgCARf4kCO4ia6GhO4giNpIj0UaCOXEJrLVQDYaHs6HBkyKgRnIvhDMOEcbVW8InsqHpNEnxOfEsGbwWz02OhhAbZMByHebNJkAAAAAElFTkSuQmCC" />
        Appointment
      </button>
      {isModalOpenSearchAppointment && (
        <div className="modal" ref={modalRef}>
          <AppointmentSearchPage setIsModalOpenSearchAppointment={setIsModalOpenSearchAppointment} />
        </div>
      )}
    </div>
  );
};

export default CustomCalendar;
