import React, { useState } from "react";
import "./ClinicDetailsCard.css";
import { Link } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AddSlot from "./AddSlot";
import Select from "react-select";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import AddDoctorPopup from "./AddDoctorPopup";
import AddAssistantPopup from "./AddAssistantPopup";
import { useApp } from "../../../AppProvider";
import { useAuth } from "../../../AuthProvider";
import { useMemo } from "react";

const options = [
  { value: "Online", label: "Online" },
  { value: "Offline", label: "Offline" },
];

const initialAdditionalImages = [
  "https://images.pexels.com/photos/7108332/pexels-photo-7108332.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  "https://www.stinabooth.com/wp-content/uploads/2017/05/07nmcclinics-studiosb.jpg",
];

const availableAssistants = [
  {
    assistantName: "Assistant 1",
    assistantImage:
      "https://img.freepik.com/free-photo/portrait-smiling-young-man-standing-with-arms-crossed-white-background_171337-1002.jpg?w=1060",
    assistantSpecialization: "Nurse",
  }
];

const ClinicDetailsCard = () => {
  const { accessToken, user } = useAuth();
  const { selectedClinic, setSelectedClinic, doctor, setDoctor } = useApp();
  const [addSlotPopUp, setAddSlotPopUp] = useState(false);
  const [showMoreImages, setShowMoreImages] = useState(false);
  const [fullScreenImageIndex, setFullScreenImageIndex] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [additionalImages, setAdditionalImages] = useState(initialAdditionalImages);
  const [addDoctorPopup, setAddDoctorPopup] = useState(false);
  const [addAssistantPopup, setAddAssistantPopup] = useState(false);
  const [assistants, setAssistants] = useState(availableAssistants);
  const [isEditingLocation, setIsEditingLocation] = useState(false);
  const [locationInput, setLocationInput] = useState(selectedClinic?.geoCoordinates?.coordinates || '');

  const removeAvailableSlotCapsule = async (slot, day) => {
    const clinicId = selectedClinic?._id;
    console.log(slot, "SLOT")
    const updatedTimeSlots = selectedClinic.doctorTimings
      .filter((obj) => obj.doctorId.toString() === user._id.toString())[0].schedule
      .filter((obj) => obj != slot)
    const newClinic = {
      ...selectedClinic,
      doctorTimings: selectedClinic.doctorTimings.map((doctorTiming) =>
        doctorTiming.doctorId.toString() === user.doctorId.toString()
          ? {
            ...doctorTiming,
            schedule: [...updatedTimeSlots], // Ensure a new array reference
          }
          : doctorTiming
      ),
    }
    console.log(newClinic)
    setSelectedClinic(newClinic);

    console.log(updatedTimeSlots, "updatedTimeSlots")
    try {
      const response = await fetch(`https://dev3.backend.cohesivehealth.in/clinics/${clinicId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${accessToken}`
        },
        body: JSON.stringify({ doctorTimings: { doctorId: user._id.toString(), schedule: updatedTimeSlots } }),
      });

      if (!response.ok) {
        throw new Error('Failed to update time slots');
      }

      const result = await response.json();
      console.log('Time slot updated successfully:', JSON.stringify({ doctorTimings: { doctorId: user._id.toString(), schedule: updatedTimeSlots } }));
    } catch (error) {
      console.error('Error updating time slot:', error);
    }
  };



  const openFullScreenImage = (index) => {
    setFullScreenImageIndex(index);
  };

  const closeFullScreenImage = () => {
    setFullScreenImageIndex(null);
  };

  const showNextImage = () => {
    setFullScreenImageIndex(
      (prevIndex) => (prevIndex + 1) % additionalImages.length
    );
  };

  const showPreviousImage = () => {
    setFullScreenImageIndex(
      (prevIndex) => (prevIndex - 1 + additionalImages.length) % additionalImages.length
    );
  };

  const removeImage = (index) => {
    setAdditionalImages(additionalImages.filter((_, i) => i !== index));
  };

  const addImage = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setAdditionalImages([...additionalImages, reader.result]);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleAddDoctor = (doctors) => {
    setDoctor([...doctor, doctors]);
  };

  const handleRemoveDoctor = async (index) => {
    const updatedDoctors = [...doctor];
    const doctorId = updatedDoctors[index]._id;

    updatedDoctors.splice(index, 1);
    setDoctor(updatedDoctors);
    // console.log("doctorId", doctorId);

    try {
      const response = await fetch(`https://dev3.backend.cohesivehealth.in/doctors/${doctorId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${accessToken}`
        },
        // body: JSON.stringify({ doctor: [{ doctorId: doctorId }] }),
      });

      if (!response.ok) {
        throw new Error('Failed to delete doctor');
      }
      console.log('Doctor removed successfully');
    } catch (error) {
      console.error('Network or CORS error:', error);
    }
  };


  const handleAddAssistant = (assistant) => {
    setAssistants([...assistants, assistant]);
  };

  const handleRemoveAssistant = (index) => {
    setAssistants(assistants.filter((_, i) => i !== index));
  };

  const toggleEditLocation = () => {
    setIsEditingLocation(!isEditingLocation);
  };

  const handleLocationChange = (event) => {
    setLocationInput(event.target.value);
  };
  const hashObject = (obj) => JSON.stringify(obj);
  const slotsByDay = useMemo(() => {
    console.log("SLOTS BY DAY IS CHANGED")
    if (!selectedClinic?.doctorTimings) return {};

    const doctorTiming = selectedClinic.doctorTimings.find(
      (obj) => obj.doctorId.toString() === user._id.toString()
    );

    if (!doctorTiming || !doctorTiming.schedule) return {};
    console.log("SLOTS BY DAY IS CHANGED", doctorTiming)
    const unorderedSlots = doctorTiming.schedule.reduce((acc, slot) => {
      acc[slot.day] = acc[slot.day] || [];
      acc[slot.day].push(slot);
      return acc;
    }, {});
    const daysOrder = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
    const orderedSlots = {};

    daysOrder.forEach((day) => {
      if (unorderedSlots[day]) {
        orderedSlots[day] = unorderedSlots[day];
      }
    });

    return orderedSlots;
  }, [selectedClinic, user._id, hashObject(selectedClinic?.doctorTimings)]);
  console.log("SHEDULE = ", slotsByDay)


  return (
    <div className="clinic-details-main-container">
      <div className="doctorClinic-details-card_Container">
        <Link className="doctorClinicDetails-card-BackLink" to="/setting/doctorclinic">
          <ArrowBackIosIcon />
          <div>Go Back</div>
        </Link>
        <div className="doctorClinicDetails-logo-Header">
          {/* <img
            src="https://th.bing.com/th/id/OIP.Na0I7neN1sPncMmJ3WZqsQAAAA?rs=1&pid=ImgDetMain"
            alt="clinicLogo"
          /> */}
          <h2>{selectedClinic.name.fullName}</h2>
        </div>
        <div className="blank-section"></div>
      </div>
      <div className="clinic-details-container">
        <div className="clinic-details-left_section">
          <header className="header">
            <div className="main-image">
              <img
                src="https://www.yashodahealthcare.com/blogs/wp-content/uploads/2023/01/radiology-diagnostic-imaging-at-yashoda-hospital-768x512.webp"
                alt="MainProperty"
              />
            </div>
            <div className="side-images">
              <img
                src="https://www.stinabooth.com/wp-content/uploads/2017/05/07nmcclinics-studiosb.jpg"
                alt="RoomPhoto"
                onClick={() => openFullScreenImage(1)}
              />
              <img
                src="https://images.pexels.com/photos/7108332/pexels-photo-7108332.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt="GuestPhoto"
                onClick={() => openFullScreenImage(0)}
              />
            </div>
            <div className="side-images">
              <img
                src="https://www.stinabooth.com/wp-content/uploads/2017/05/07nmcclinics-studiosb.jpg"
                alt="RoomPhoto"
                onClick={() => openFullScreenImage(3)}
              />
              <div className="see-more-image-container">
                <img
                  src="https://images.pexels.com/photos/7108332/pexels-photo-7108332.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                  alt="GuestPhoto"
                  onClick={() => openFullScreenImage(2)}
                />
                <button
                  className="see-more-button"
                  onClick={() => setShowMoreImages(true)}
                >
                  See More
                </button>
              </div>
            </div>
          </header>
          <div className="clinic-details-left_section-header-right">
            <div className="clinic-details_about">
              <h3 className="about_Heading">About</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incidi dunt ut labore Ut enim ad minim veniam,
                quis Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                sed do eiusmod tempor incidi dunt ut labore Ut enim ad minim
                veniam, quisLorem ipsum dolor sit amet, consectetur adipiscing
                elit, sed do eiusmod tempor incidi dunt ut labore Ut enim ad
                minim veniam, quis{" "}
              </p>
              <div className="clinic-details_location-container">
                <div className="clinic-location-box">
                  <LocationOnIcon className="clinic_location-e" />
                  {isEditingLocation ? (
                    <input
                      type="text"
                      value={locationInput}
                      onChange={handleLocationChange}
                      className="location-input"
                    />
                  ) : (
                    locationInput
                  )}
                </div>
                <div
                  className="clinic-details_edit-on-map"
                  onClick={toggleEditLocation}
                >
                  {isEditingLocation ? "Save Location" : "Edit On Map"}
                </div>
              </div>
            </div>
            <hr className="horizintal-line-clinic-details-card" />
            <div className="clinic-details_slot-section">
              <div className="headingcontainer" onClick={() => { setAddSlotPopUp(true); }} >
                <h3 className="slot_Heading">Available Slot</h3>
                <span className="add-slot">
                  <AccessTimeIcon /> Add Slot
                </span>
              </div>
              <div className="clinic-details_slot-section-container">
                <div className="day-column">
                  {Object.keys(slotsByDay).map((day) => (
                    <span key={day} className="day-box">{day}</span>
                  ))}
                </div>
                <div className="time-slot-column">
                  {Object.keys(slotsByDay).map((day) => (
                    <div key={day} className="morning-time-column">
                      {slotsByDay[day].map((slot, index) => (
                        <span key={`${day}-${slot.startTime.hour}-${slot.startTime.minute}`} className="time-box">
                          {`${slot.startTime.hour}:${slot.startTime.minute.toString().padStart(2, '0')} - ${slot.endTime.hour}:${slot.endTime.minute.toString().padStart(2, '0')}`}
                          <CloseIcon size={14} className="available-slot-cross_icon" onClick={() => removeAvailableSlotCapsule(slot, day)} />
                        </span>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr className="horizintal-line-clinic-details-card" />
      <div className="avilableDoctor_container-main">
        <h3 className="avilable-doctor-heading">Available Doctor</h3>
        <span
          className="avilable-doctor_add-button"
          onClick={() => setAddDoctorPopup(true)}
        >
          <AddIcon /> Add New Doctors
        </span>
      </div>

      <div className="clini-details-doctor_container">
        {doctor.map((item, index) => (
          <div className="clinic-details_doctor-box" key={index}>
            <div className="doctor-image-container">
              <img src={item.doctorImage || "https://img.freepik.com/premium-photo/medical-concept-indian-beautiful-female-doctor-white-coat-with-stethoscope-waist-up-medical-student-woman-hospital-worker-looking-camera-smiling-studio-blue-background_185696-621.jpg?w=1060"} alt="drImage" />
            </div>
            <div className="doctor-text">
              <h3 className="doctor-name">{item.name.firstName + " " + item.name.middleName + " " + item.name.lastName}</h3>
              <p className="doctor-specialization">
                {item.specilizations}
              </p>
              <Select
                className="Online-Offline_selector"
                defaultValue={selectedOption}
                onChange={setSelectedOption}
                options={options}
              />
            </div>
            <CloseIcon className="remove-doctor-icon" onClick={() => handleRemoveDoctor(index, item._id)} />
          </div>
        ))}
      </div>
      <hr className="horizintal-line-clinic-details-card" />
      <div className="avilableDoctor_container-main">
        <h3 className="avilable-doctor-heading">Assistants</h3>
        <span
          className="avilable-doctor_add-button"
          onClick={() => setAddAssistantPopup(true)}
        >
          <AddIcon /> Add Assistant
        </span>
      </div>

      <div className="clini-details-doctor_container">
        {assistants.map((item, index) => (
          <div className="clinic-details_doctor-box" key={index}>
            <div className="doctor-image-container">
              <img src={item.assistantImage} alt="assistantImage" />
            </div>
            <div className="doctor-text">
              <h3 className="doctor-name">{item.assistantName}</h3>
              <p className="doctor-specialization">
                {item.assistantSpecialization}
              </p>
              <Select
                className="Online-Offline_selector"
                defaultValue={selectedOption}
                onChange={setSelectedOption}
                options={options}
              />
            </div>
            <CloseIcon className="remove-doctor-icon" onClick={() => handleRemoveAssistant(index)} />
          </div>
        ))}
      </div>

      {/* ADD SLOT POP-UP RENDER HERE */}
      {addSlotPopUp && <AddSlot setAddSlotPopUp={setAddSlotPopUp} />}
      {/* ADD DOCTOR POP-UP RENDER HERE */}
      {addDoctorPopup && (
        <AddDoctorPopup setAddDoctorPopup={setAddDoctorPopup} onSubmit={handleAddDoctor} />
      )}
      {/* ADD ASSISTANT POP-UP HERE */}
      {addAssistantPopup && (
        <AddAssistantPopup setAddAssistantPopup={setAddAssistantPopup} onSubmit={handleAddAssistant} />
      )}
      {/* SHOW IMAGE POP-UP REANDER HERE */}
      {showMoreImages && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={() => setShowMoreImages(false)}>
              &times;
            </span>
            <div className="modal-images">
              {additionalImages.map((image, index) => (
                <div className="image-container" key={index}>
                  <img
                    src={image}
                    alt={`Additional ${index + 1}`}
                    onClick={() => openFullScreenImage(index)}
                  />
                  <CloseIcon
                    className="remove-icon"
                    onClick={() => removeImage(index)}
                  />
                </div>
              ))}
              <div className="image-container add-image-container">
                <input
                  type="file"
                  accept="image/*"
                  onChange={addImage}
                  className="add-image-input"
                />
                <AddIcon className="add-icon" />
              </div>
            </div>
          </div>
        </div>
      )}
      {fullScreenImageIndex !== null && (
        <div className="fullscreen-modal">
          <div className="fullscreen-modal-content">
            <span className="close" onClick={closeFullScreenImage}>
              &times;
            </span>
            <img
              src={additionalImages[fullScreenImageIndex]}
              alt="Full Screen"
            />
            <button className="prev-button" onClick={showPreviousImage}>
              &lt;
            </button>
            <button className="next-button" onClick={showNextImage}>
              &gt;
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClinicDetailsCard;
