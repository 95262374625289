import React, { useState } from 'react';
import "./AddSlot.css";
import Select from 'react-select';
import ClearIcon from '@mui/icons-material/Clear';
import { useAuth } from '../../../AuthProvider';
import { useApp } from '../../../AppProvider';

const dayOptions = [
    { value: 'Sunday', label: 'Sunday' },
    { value: 'Monday', label: 'Monday' },
    { value: 'Tuesday', label: 'Tuesday' },
    { value: 'Wednesday', label: 'Wednesday' },
    { value: 'Thursday', label: 'Thursday' },
    { value: 'Friday', label: 'Friday' },
    { value: 'Saturday', label: 'Saturday' }
];

const AddSlot = ({ setAddSlotPopUp }) => {
    const { accessToken,user } = useAuth();
    const { selectedClinic, setSelectedClinic } = useApp();
    const [timeSlots, setTimeSlots] = useState([{ start: "", end: "", day: [] }]);

    const handleTimeSlotChange = (index, field, value) => {
        const newTimeSlots = [...timeSlots];
        newTimeSlots[index][field] = value;
        setTimeSlots(newTimeSlots);
    };

    const addTimeSlot = () => {
        setTimeSlots([...timeSlots, { start: "", end: "", day: [] }]);
    };

    const removeTimeSlot = (index) => {
        const newTimeSlots = timeSlots.filter((_, i) => i !== index);
        setTimeSlots(newTimeSlots);
    };

    const saveSlots = async () => {
        const clinicId = selectedClinic?._id;
        const formattedTimeSlots = timeSlots.map(slot =>
            slot.day.map(day => ({
                day: day.value.slice(0, 3),
                startTime: {
                    hour: slot.start.split(":")[0].padStart(2, '0'),
                    minute: slot.start.split(":")[1].padStart(2, '0'),
                },
                endTime: {
                    hour: slot.end.split(":")[0].padStart(2, '0'),
                    minute: slot.end.split(":")[1].padStart(2, '0'),
                },
                _id: slot._id || undefined // Include _id if it exists, otherwise undefined
            }))
        ).flat();

        const updatedSchedule = [
            ...selectedClinic.doctorTimings[0].schedule,
            ...formattedTimeSlots
        ];

        // setSelectedClinic({
        //     ...selectedClinic,
        //     doctorTimings: [
        //         {
        //             ...selectedClinic.doctorTimings[0],
        //             schedule: updatedSchedule,
        //         },
        //     ],
        // });
        setSelectedClinic({
            ...selectedClinic,
            doctorTimings: selectedClinic.doctorTimings.map((doctorTiming) =>
                doctorTiming.doctorId.toString() === user.doctorId.toString() // Replace `targetDoctorId` with the actual ID
                    ? {
                        ...doctorTiming,
                        schedule: updatedSchedule, // Use the new schedule for the target doctor
                      }
                    : doctorTiming // Keep other doctorTimings unchanged
            ),
        });
        try {
            const response = await fetch(`https://dev3.backend.cohesivehealth.in/clinics/${clinicId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${accessToken}`
                },
                // body: JSON.stringify({ doctorTimings: [{ schedule: updatedSchedule }] }),
                body: JSON.stringify({ doctorTimings: {doctorId:user._id.toString(), schedule:updatedSchedule} }),
            });

            if (!response.ok) {
                throw new Error('Failed to save time slots');
            }

            const result = await response.json();
            console.log("Save successful:", result);
            alert('Time slots saved successfully.');
            setAddSlotPopUp(false);
        } catch (error) {
            console.error("Error saving time slots:", error);
            alert('There was an error saving the time slots. Please try again.');
        }
    };

    return (
        <div className="slot-overlay-page_container">
            <div className="slot-main_container">
                <div>
                    <div className="close-action" onClick={() => { setAddSlotPopUp(false) }}>
                        <ClearIcon />
                    </div>
                    <h3 className="slot-title">Add Slot</h3>
                    <hr />
                    <div className="time-input_container">
                        {timeSlots.map((slot, index) => (
                            <div key={index}>
                                <span className='slot-title-count'>Slot {index + 1}</span>
                                <div className="time-slot_container">
                                    <input
                                        type="time"
                                        value={slot.start}
                                        onChange={(e) => handleTimeSlotChange(index, 'start', e.target.value)}
                                        required
                                    />
                                    <input
                                        type="time"
                                        value={slot.end}
                                        onChange={(e) => handleTimeSlotChange(index, 'end', e.target.value)}
                                        required
                                    />
                                </div>
                                <Select
                                    options={dayOptions}
                                    isMulti
                                    placeholder="Select Days"
                                    value={slot.day}
                                    onChange={(selectedDays) => handleTimeSlotChange(index, 'day', selectedDays)}
                                />
                                {index > 0 && (
                                    <div
                                        type="button"
                                        className="add-slot-remove-time-slot-button"
                                        onClick={() => removeTimeSlot(index)}
                                    >
                                        Remove Slot
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                    <div className="add-button-new" onClick={addTimeSlot}>Add New Slot</div>
                </div>
                <div className='button_container-addslot'>
                    <div className="add-button-save" onClick={saveSlots}>Save</div>
                </div>
            </div>
        </div>
    );
};

export default AddSlot;
