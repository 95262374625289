import React, { useEffect, useState, useRef } from "react";
import SearchIcon from "./image/Search.png";
import CrossIcon from "./image/cross-svgrepo-com.svg";
import "./Search.css";
import { useAuth } from "../../../AuthProvider";
import { useApp } from "../../../AppProvider";
import { Link } from "react-router-dom";
import PatientForm from "../../../Components/Appointment/PatientForm";

const Search = ({ open, onClose }) => {
  const { accessToken } = useAuth();
  const { setSelectedPatient } = useApp();
  const [searchPatientsData, setSearchPatientsData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isPatientFormOpen, setIsPatientFormOpen] = useState(false);
  const popupRef = useRef(null); 

  const fetchSearchPatientsAPI = async (searchQuery) => {
    if (!open) return;

    const apiUrl = `https://dev3.backend.cohesivehealth.in/search/patients/?searchQuery=${searchQuery}`;
    try {
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${accessToken}`,
        },
      });
      if (!response.ok) {
        const errorMessage = await response.text();
        throw new Error(`Fetch error: ${response.status} - ${errorMessage}`);
      }
      const data = await response.json();
      setSearchPatientsData(data);
    } catch (error) {
      console.error("Error fetching search patients data:", error);
    }
  };

  useEffect(() => {
    if (open) {
      fetchSearchPatientsAPI(searchQuery);
    }
  }, [open, searchQuery]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setSearchPatientsData([]);
  };

  const handleSelectPatient = (patient) => {
    setSearchQuery(`${patient.name.firstName} ${patient.name.middleName} ${patient.name.lastName}`);
    setSelectedPatient(patient._id);
    setSearchPatientsData([]); 
  };

  const openPatientForm = () => {
    setIsPatientFormOpen(true);
  };

  const closePatientForm = () => {
    setIsPatientFormOpen(false);
  };

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      onClose(); 
    }
  };

  useEffect(() => {
    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open]);

  function getEventColor(eventType) {
    switch (eventType) {
      case "New":
        return "new-bg-searchPop";
      case "Regular":
        return "regular-bg-searchPop";
      case "Critical":
        return "critical-bg-searchPop";
      case "Published":
        return "published-bg-searchPop";
      default:
        return "";
    }
  }

  function getTextColor(eventType) {
    switch (eventType) {
      case "New":
        return "new-searchPop";
      case "Regular":
        return "regular-searchPop";
      case "Critical":
        return "critical-searchPop";
      case "Published":
        return "published-searchPop";
      default:
        return "";
    }
  }

  return (
    <div className="search-main-background-overlay" ref={popupRef}>
      <div className="search-main-background">
        <div className="cross-icon_container" onClick={onClose}>
          <img className="cross_icon-image" src={CrossIcon} alt="close" />
        </div>
        <div className="searchpop">
          <img src={SearchIcon} alt="search icon" />
          <input
            className="searchtext"
            type="text"
            placeholder="Search Patients"
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </div>
        <div>
          {searchPatientsData.map((item, index) => (
            <Link
              to="/DashboardDashboardNavigation/history"
              className="search-card"
              key={index}
              onClick={() => handleSelectPatient(item)}
            >
              <div className="search-section-1">
                <img src={item.img} alt="" />
                <p>{`${item.name.firstName} ${item.name.middleName} ${item.name.lastName}`}</p>
              </div>
              <div className="search-btn">
                <div className={`search-tag-button ${getEventColor(item.tag)} ${getTextColor(item.tag)}`}>
                  {item.tag}
                </div>
              </div>
            </Link>
          ))}
        </div>
        <div className="search-add-patient-button-container">
          <button className="search-add-patient-button" onClick={openPatientForm}>Add Patient</button>
        </div>
      </div>

      {isPatientFormOpen && <PatientForm onClose={closePatientForm} />}
    </div>
  );
};

export default Search;
