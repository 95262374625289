import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useApp } from "../../../../AppProvider";
import DeleteIcon from "../../../Setting/SettingsAssets/Delete.svg";
import SeacrhIcon from "../../../Setting/SettingsAssets/Search.svg";
import "./MedicineMain.css";
import { useAuth } from "../../../../AuthProvider";
// import ComponentsLoader from "../../../../Components/ComponentsLoader/ComponentsLoader";


const MedicineMain = ({ handleDeleteCard, selectedCard }) => {
  const menuPortalRef = useRef(null);
  const { accessToken } = useAuth();
  const [tags, setTags] = useState(selectedCard?.tags || []);
  const [mediTableContent, setMediTableContent] = useState([]);
  const [advice, setAdvice] = useState(selectedCard?.advice || 'Add Advice');
  const [groupName, setGroupName] = useState(selectedCard?.groupName || 'Group Details');
  const [loading, setLoading] = useState();
  const [popupMessage, setPopupMessage] = useState();
  const { searchMedicineData, searchQuery, setSearchQuery, setSearchMedicineData, setMedicationGroup, } = useApp();


  const updateMedicineNewData = async (id, groupName, medicines, advice, tags) => {
    const apiUrl = `https://dev3.backend.cohesivehealth.in/medicationGroups/${id}`;
    try {
      const response = await fetch(apiUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${accessToken}`,
        },
        body: JSON.stringify({ groupName, medicines, advice, tags }),
      });

      if (!response.ok) {
        throw new Error(`Fetch error: ${response.status} - ${response.statusText}`);
      }

      const data = await response.json();
      console.log("Updated medicine new data:", data);
      return data

    } catch (error) {
      console.error("Error updating medicine data:", error.message);
    }
  };

  useEffect(() => {
    if (selectedCard) {
      setGroupName(selectedCard.groupName || 'Group Details');
      setMediTableContent(selectedCard.medicines || []);
      setAdvice(selectedCard.advice || "");
      setTags(selectedCard.tags || []);
    }
  }, [selectedCard]);

  const handleSave = async () => {
    if (selectedCard && selectedCard._id) {
      setLoading(true);
      try {
        const updatedData = await updateMedicineNewData(selectedCard._id, groupName, mediTableContent, advice, tags);
        setMedicationGroup(prevState =>
          prevState.map(item =>
            item._id === selectedCard._id
              ? updatedData
              : item
          )
        );
        setPopupMessage("Update successful!");
      } catch (error) {
        console.error("Error updating data:", error);
        setPopupMessage("Error updating data.");
      } finally {
        setLoading(false);
      }

    }
  };

  const handleAdviceChange = (e) => {
    setAdvice(e.target.value);
  };

  const handleTagsChange = (e) => {
    const value = e.target.value;
    const tagsArray = value.split(',').map(tag => tag.trim());
    setTags(tagsArray);
  };

  const handleHeadingChange = (e) => {
    const newGroupName = e.target.value;
    setGroupName(newGroupName);
    if (selectedCard) {
      selectedCard.groupName = newGroupName;
    }
  };


  const handleDeleteClickTableItem = (index) => {
    const updatedMediTableContent = mediTableContent.filter((item, idx) => idx !== index);
    setMediTableContent(updatedMediTableContent);
  };

  const handleResultClickMedication = (item) => {
    setSearchMedicineData([]);
    setSearchQuery(item.sku);
    const newMedicine = {
      serialNo: (mediTableContent.length + 1).toString(),
      brand: item.sku,
      drugsName: item.subGroup,
      quantity: "",
      duration: "",
      dosage: "",
      remarks: "",
      skuId: item._id
    };
    setMediTableContent([...mediTableContent, newMedicine]);
    setSearchQuery("");
  };

  const handleInputChange = (index, columnKey, value) => {
    const updatedContent = mediTableContent.map((item, idx) => {
      if (idx === index) {
        return { ...item, [columnKey]: value };
      }
      return item;
    });
    setMediTableContent(updatedContent);
  };

  const handleSearchChangeBrandMedication = (event) => {
    const searchQuery = event.target.value;
    setSearchQuery(searchQuery);
  };



  return (
    <div className="medi_right_container" ref={menuPortalRef}>
      {(loading || popupMessage) && (
        // <ComponentsLoader />
        <div className="popup-overlay">
          <div className="popup-content">
            <p>{popupMessage || 'Loading...'}</p>
          </div>
        </div>
      )}
      <div className="medi_right_section">
        <div className="medi_right_top">
          <Link className="backLink" to="/setting">
            <ArrowBackIosIcon />
            <div>Go Back</div>
          </Link>
          <input className="medi_right_heading"
            type="text"
            value={selectedCard === undefined ? "Group Details" : selectedCard ? selectedCard.groupName : "A"}
            onChange={handleHeadingChange}
          />
          <div className="medi_right_content">
            <div className="medi_search_inputbox">
              {/* SEARCH BAR BRAND MEDICINE */}
              <div className="medi_search_input">
                <img className="medi_search_icon" src={SeacrhIcon} alt="Search Icon" />
                <input
                  className="medi_input_search"
                  type="text"
                  placeholder="search brand"
                  value={searchQuery}
                  onChange={handleSearchChangeBrandMedication}
                />
                {searchMedicineData.length > 0 && (
                  <div className="medicine-search-results_medication">
                    {searchMedicineData.map((item, index) => (
                      <div
                        className="medicine-brandsearch_medication"
                        key={index}
                        onClick={() => handleResultClickMedication(item)}
                      >
                        {item.sku}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="medi_search_input">
                <img className="medi_search_icon" src={SeacrhIcon} alt="Search Icon" />
                <input
                  className="medi_input_search"
                  type="text"
                  placeholder="search test"
                />
                {/* {searchMedicineData.length > 0 && (
                  <div className="medicine-search-results_medication">
                    {searchMedicineData.map((item, index) => (
                      <div
                        className="medicine-brandsearch_medication"
                        key={index}
                        onClick={() => handleResultClickMedication(item)}
                      >
                        {item.sku}
                      </div>
                    ))}
                  </div>
                )} */}
              </div>
            </div>
            <div className="medi_table_container-medication">
              <div className="medi_table_content-medication">
                <table className="medi_table-medication">
                  <thead>
                    <tr className="medi_tableheader-medication">
                      <td className="medi_header_text-medication">S.No.</td>
                      <td className="medi_header_text-medication">Brand Name</td>
                      <td className="medi_header_text-medication">Drug Name</td>
                      <td className="medi_header_text-medication">Quantity</td>
                      <td className="medi_header_text-medication">Duration</td>
                      <td className="medi_header_text-medication">Dosage</td>
                      <td className="medi_header_text-medication">Delete</td>
                    </tr>
                  </thead>
                  <tbody>
                    {mediTableContent.length > 0 ? (
                      mediTableContent.map((group, index) => (
                        <tr className="theBody-tr_medication" key={index}>
                          <td className="medi_table_el-medication">{index + 1}</td>
                          <td className="medi_table_el-medication">{group.brand}</td>
                          <td className="medi_table_el-medication">{group.drugsName}</td>
                          <td className="medi_table_el-medication">
                            <input
                              className="medicine-table-input"
                              type="text"
                              placeholder="Enter quantity"
                              value={group.quantity}
                              onChange={(e) => handleInputChange(index, 'quantity', e.target.value)}
                              list={`quantity-options-${group.serialNo}`}
                            />
                            <datalist id={`quantity-options-${group.serialNo}`}>
                              <option value="1 tablet"></option>
                              <option value="2 tablets"></option>
                              <option value="3 tablets"></option>
                              <option value="4 tablets"></option>
                              <option value="5 tablets"></option>
                            </datalist>
                          </td>
                          <td className="medi_table_el-medication">
                            <input
                              type="text"
                              placeholder="Enter duration"
                              value={group.duration}
                              onChange={(e) => handleInputChange(index, 'duration', e.target.value)}
                              className="medicine-table-input"
                              list={`duration-options-${group.serialNo}`}
                            />
                            <datalist id={`duration-options-${group.serialNo}`}>
                              <option value="1 day"></option>
                              <option value="3 days"></option>
                              <option value="1 week"></option>
                              <option value="2 weeks"></option>
                              <option value="1 month"></option>
                            </datalist>
                          </td>
                          <td className="medi_table_el-medication">
                            <input
                              type="text"
                              placeholder="Enter dosage"
                              value={group.dosage}
                              onChange={(e) => handleInputChange(index, 'dosage', e.target.value)}
                              className="medicine-table-input"
                              list={`dosage-options-${group.serialNo}`}
                            />
                            <datalist id={`dosage-options-${group.serialNo}`}>
                              <option value="Once a day"></option>
                              <option value="Twice a day"></option>
                              <option value="Thrice a day"></option>
                              <option value="As needed"></option>
                            </datalist>
                          </td>
                          <td className="medi_table_el-medication">
                            <img
                              className="medicine-delete_icon_doctor"
                              src={DeleteIcon}
                              alt="deleteicon"
                              onClick={() => handleDeleteClickTableItem(index)}
                            />
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="8" className="no_medicine_selected">
                          No Medicine Selected
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="medi_table_container-lab">
              <div className="medi_table_content-lab">
                <table className="medi_table-lab">
                  <thead>
                    <tr className="medi_tableheader-lab">
                      <td className="medi_header_text-lab">S.No.</td>
                      <td className="medi_header_text-lab">Lab Name</td>
                      <td className="medi_header_text-lab">Test</td>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedCard && selectedCard.tests ? (
                      selectedCard.tests.length > 0 ? (
                        selectedCard.tests.map((group, index) => (
                          <tr className="theBody-tr_lab" key={index}>
                            <td className="medi_table_el-lab">{index + 1}</td>
                            <td className="medi_table_el-lab">{group.testTpye}</td>
                            <td className="medi_table_el-lab">{group.test}</td>
                            <td className="medi_table_el-medication">
                              <img
                                className="medicine-delete_icon_doctor"
                                src={DeleteIcon}
                                alt="deleteicon"
                              // onClick={() => handleDeleteClickTableItem(index)}
                              />
                            </td>
                          </tr>

                        ))
                      ) : (
                        <tr>
                          <td colSpan="3" className="no_medicine_selected">
                            No Test Selected
                          </td>
                        </tr>
                      )
                    ) : (
                      <tr>
                        <td colSpan="3" className="no_medicine_selected">
                          No Test Selected
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="medi_suggestionbox">
              <div className="medi_suggestion_label">
                Advice / Suggestions
              </div>
              <div className="medi_suggestion_inputbox">
                <textarea
                  className="medi_suggestion_input"
                  type="text"
                  placeholder="Advice / Suggestions text here "
                  value={advice}
                  onChange={handleAdviceChange}
                />
              </div>
            </div>
            <div className="medi_suggestionbox">
              <div className="medi_suggestion_label">
                Tags
              </div>
              <div className="medi_suggestion_inputbox">
                <textarea
                  className="medi_suggestion_input"
                  type="text"
                  placeholder="Add Tags here "
                  value={tags}
                  onChange={handleTagsChange}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="btn_box">
          <div
            className="medi_btn_deletebox"
            onClick={() => handleDeleteCard()}
          >
            <div className="medi_btn_deletetext">Delete</div>
          </div>
          <div className="medi_btn_savebox">
            <div className="medi_btn_savetext" onClick={handleSave}>Save</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MedicineMain;
