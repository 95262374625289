import React, { useState } from 'react'
import ReactApexChart from "react-apexcharts";

const LineChart = () => {
    const [state, setState] = useState({
        series: [{
            // name: "Vitamin B12",
            data: [180, 210, 280, 190, 220, 100, 260] // Sample data points
        }],
        options: {
            chart: {
                toolbar: {
                    show: false, // Disables the download/export menu
                },
                height: 350,
                type: 'line',
                zoom: {
                    enabled: false
                }
            },
            colors: ['#00E396', '#FF4560'], // Green for normal, red for abnormal
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'straight'
            },
            xaxis: {
                categories: ['1 May', '15 May', '30 May', '15 Jun', '30 Jun', '15 jul', '30 jul'],
            },
            yaxis: {
                min: 100,
                max: 350,
                title: {
                    text: 'Vitamin B12',
                    style: { color: '#f39c12' }
                }
            },
            annotations: {
                yaxis: [
                    {
                        y: 240, // Threshold for abnormal
                        y2: 350,
                        borderColor: '#FF4560',
                        fillColor: '#FF4560',
                        opacity: 0.1,
                        label: {
                            text: 'Abnormal Range',
                            style: {
                                color: '#fff',
                                background: '#FF4560'
                            }
                        }
                    }
                ]
            },
            markers: {
                size: 6,
                colors: ['#00E396'], // Points
                discrete: [
                    { seriesIndex: 0, dataPointIndex: 2, fillColor: '#FF4560', strokeColor: '#FF4560' }, // Red for abnormal
                ]
            }
        },
    });

    return (
        <div style={{ width: '80%' }}>
            <div id="chart">
                <ReactApexChart options={state.options} series={state.series} type="line" height={350} />
            </div>
            <div id="html-dist"></div>
        </div>
    );

}

export default LineChart