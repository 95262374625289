import React, { useState } from 'react';
import './EditBookedAppointment.css';
import CloseIcon from '@mui/icons-material/Close';
import DatePicker from 'react-datepicker';
import { useAuth } from '../../AuthProvider';
import { useApp } from '../../AppProvider';
import Select from 'react-select';


const tagOptions = [
    { value: 'New', label: 'New' },
    { value: 'Critical', label: 'Critical' },
    { value: 'Regular', label: 'Regular' },
    { value: 'Published', label: 'Published' }
];

const appNameOptions = [
    { value: 'New', label: 'New' },
    { value: 'Regular Checkup', label: 'Regular Checkup' },
    { value: 'Follow Up', label: 'Follow Up' }
];

const EditBookedAppointment = ({ setEditModalOpen, task }) => {
    const { accessToken, user } = useAuth();
    const { selectedClinic, fetchClinicData, appointments } = useApp();
    const selectedAppointment = appointments.find(appointment => appointment._id.toString() === task.id.toString())

    const [selectedDate, setSelectedDate] = useState(new Date(selectedAppointment.dateOfAppointment));
    const [selectedTime, setSelectedTime] = useState(() => {
        const appointmentDate = new Date(selectedAppointment.dateOfAppointment);
        return {
            startTime: {
                hour: appointmentDate.getHours(),
                minute: appointmentDate.getMinutes()
            },
        };
    });
    const [timeSlotsApi, setTtimeSlotsApi] = useState([]);
    const [selectedNameOption, setSelectedNameOption] = useState(appNameOptions.filter(tag => selectedAppointment.appointmentName.includes(tag.value)));
    const [selectedTag, setSelectedTag] = useState(tagOptions.filter(tag => selectedAppointment.tags.includes(tag.value)));
    const [editAppointmentLoader, setEditAppointmentLoader] = useState(false);  // Corrected here

    const closeModal = () => {
        setEditModalOpen(false);
    };

    const fetchEmptySlots = async (date) => {
        const id = selectedClinic._id;
        const dateString = date.toISOString().slice(0, 10);
        const apiUrl = `https://dev3.backend.cohesivehealth.in/clinics/findEmptySlots/${id}?doctorId=${user._id}&date=${dateString}`;
        console.log(apiUrl);
        try {
            const response = await fetch(apiUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${accessToken}`,
                },
            });
            if (!response.ok) {
                const errorMessage = await response.text();
                throw new Error(
                    `Fetch error: ${response.status} - ${errorMessage}`
                );
            }
            const data = await response.json();
            console.log('setSearchAppointmentsData-data', data.emptySlots);
            return data.emptySlots;
        } catch (error) {
            console.error('Error fetching search patients data:', error);
            return [];
        }
    };

    const handleBookAppointment = async () => {
        // Check if time slot is selected
        if (!selectedTime) {
            alert("Please select a time slot before booking the appointment.");
            return;
        }

        // Check if tags are selected
        if (!selectedTag || selectedTag.length === 0) {
            alert("Please select at least one tag before booking the appointment.");
            return;
        }

        let dateOfAppointment;
        if (!selectedDate && !selectedTime) {
            const result = appointments.find(appointment => appointment._id.toString() === task.id.toString())
            dateOfAppointment = result.dateOfAppointment
            console.log(dateOfAppointment)
        }
        else {
            const year = selectedDate.getFullYear();
            const month = selectedDate.getMonth();
            const day = selectedDate.getDate();
            const { hour, minute } = selectedTime.startTime;
            dateOfAppointment = new Date(Date.UTC(year, month, day, hour, minute)).toISOString();
        }
        // console.log(`Appointment set for ${dateOfAppointment}`);
        const dateOfBooking = new Date();
        // console.log(selectedNameOption, 'NAME OPTIONS');
        const id = task.id;
        const apiUrl = `https://dev.gateway.cohesivehealth.in/clinics/appointmentsandclinics/${id}`;
        const body = {
            clinicId: `${selectedClinic._id}`,
            doctorId: `${user._id}`,
            patientId: `${task.patientId}`,
            doctorName: `${user.name.firstName} ${user.name.lastName}`,
            patientName: `${task.content}`,
            clinicName: `${selectedClinic.name.fullName}`,
            appointmentName: `${selectedNameOption.value}`,
            dateOfAppointment: `${dateOfAppointment}`,
            dateOfBooking: dateOfBooking.toISOString(),
            tags: selectedTag ? selectedTag.map(tag => tag.value) : [],  // Null check for selectedTag
        };
        console.log(body, "APPOINTMENT", id);
        setEditAppointmentLoader(true);
        try {
            const response = await fetch(apiUrl, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${accessToken}`,
                },
                body: JSON.stringify(body),
            });
            if (!response.ok) {
                const errorMessage = await response.text();
                throw new Error(
                    `Fetch error: ${response.status} - ${errorMessage}`
                );
            }
            const data = await response.json();
            fetchClinicData(user.clinics.join(','));
            // console.log('Appointment Scheduled', data);
            closeModal();
        } catch (error) {
            console.error('Error Scheduling Appointment:', error);
        } finally {
            setEditAppointmentLoader(false);
        }
    };


    const handelAppointmentDelete = async () => {
        const id = task.id;
        const apiUrl = `https://dev.gateway.cohesivehealth.in/appointments/${id}`;
        setEditAppointmentLoader(true);
        try {
            const response = await fetch(apiUrl, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${accessToken}`,
                },
            });
            if (!response.ok) {
                const errorMessage = await response.text();
                throw new Error(
                    `Delete error: ${response.status} - ${errorMessage}`
                );
            }
            fetchClinicData(user.clinics.join(','));
            closeModal();
        } catch (error) {
            console.error('Error Deleting Appointment:', error);
        } finally {
            setEditAppointmentLoader(false);
            closeModal();
        }
    };

    const handleDateChange = async (date) => {
        await setSelectedDate(date);
        const slots = await fetchEmptySlots(date);
        await setTtimeSlotsApi(slots);
        console.log(date.toISOString().slice(0, 10));
    };

    return (
        <>
            {editAppointmentLoader && (
                <div className="popup-overlay">
                    <div className="popup-content">
                        {editAppointmentLoader && <p>Loading...</p>}
                    </div>
                </div>
            )}
            <div className="edit-appointment_container-overlay">
                <div className="edit-appointment_container">
                    <div className="edit-appointment_header-close">
                        <CloseIcon onClick={closeModal} />
                    </div>
                    <div className="edit-appointments-header">
                        <span>{task.content}, Edit Appointments</span>
                    </div>
                    <div className="appointment-popup">
                        <div className="date-section_left">
                            <h3>Select Date</h3>
                            <DatePicker
                                className="date-picker-container"
                                selected={selectedDate}
                                onChange={handleDateChange}
                                inline
                                showYearDropdown
                                showMonthDropdown
                            />
                        </div>
                        <div className="time-slot-main">
                            <h3>Select Time Slot</h3>
                            <div className="time-slot-container">
                                {timeSlotsApi && timeSlotsApi.length > 0 ? (
                                    timeSlotsApi.map((time, index) => (
                                        <div
                                            key={index}
                                            className={`time-slot-card ${selectedTime === time ? 'selected' : ''}`}
                                            onClick={() => setSelectedTime(time)}
                                        >
                                            {`${time.startTime.hour}:${time.startTime.minute} - ${time.endTime.hour}:${time.endTime.minute}`}
                                        </div>
                                    ))
                                ) : (
                                    <div className="no-time-slots-message">
                                        Please select a date to view available slots.
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="select-slot_input-selector">
                        <Select
                            value={selectedTag}
                            onChange={(option) => setSelectedTag(option)}
                            isMulti
                            name="tags"
                            options={tagOptions}
                            className="slot-booking_basic-multi-select"
                            classNamePrefix="select"
                        />
                        <Select
                            value={selectedNameOption}
                            onChange={(option) => setSelectedNameOption(option)}
                            name="appointmentName"
                            options={appNameOptions}
                            className="slot-booking_basic-multi-select"
                            classNamePrefix="select"
                        />
                    </div>
                    <div className='set-appointment-button-container'>
                        <button className='appointment-button_book' onClick={handleBookAppointment}>
                            Save Appointment
                        </button>
                        <button className="appointment-button_delete" onClick={handelAppointmentDelete} type="submit">
                            Delete
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EditBookedAppointment;
