import React, { useState } from "react";
import "./DataVisualization.css";
import Pathology from "./images/Pathology Reports.svg";
import Medical from "./images/Medical Images.svg";
import Vaccination from "./images/Vaccination.svg";
import Prescriptions from "./images/Prescription.svg";
import Hospital from "./images/Hospital Records.svg";
import Expenses from "./images/Medical Expenses.svg";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import CustomCalendar from "../../../Components/CustomCalendar";
import CustomDropdown from "../../../Components/Dropdown/CustomDropdown";
import LineChart from './LineChart';


const optionsRecord = [
  { label: 'Lab Tests', value: 'Lab Tests' },
  { label: 'Medical Images', value: 'Medical Images' },
  { label: 'Vaccination', value: 'Vaccination' },
  { label: 'Past Medications', value: 'Past Medications' },
  { label: 'Hospitalization', value: 'Hospitalization' },
];
const optionsParameter = [
  { label: 'Parameter 1 ', value: 'Parameter1' },
  { label: 'Parameter 2', value: 'Parameter2' },
  { label: 'Parameter 3', value: 'Parameter3' },
  { label: 'Parameter 4', value: 'Parameter4' },
  { label: 'Parameter 5', value: 'Parameter5' },
];
const optionsHealthRecord = [
  { label: 'Heart Rate', value: 'Heart Rate' },
  { label: 'Blood Pressure', value: 'Blood Pressure' },
  { label: 'Pulse Rate', value: 'Pulse Rate' },
  { label: 'Glucose Level', value: 'Glucose Level' },
  { label: 'SpO2', value: 'SpO2' },
];

const DataVisualization = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [selectedRecord, setSelectedRecord] = useState('');
  const [selectedParameter, setSelectedParameter] = useState('');
  const [selectedHealthRecord, setSelectedHealthRecord] = useState('');

  return (
    <div className="data_vazualization-main_container">
      <div className="main-visualization">
        <div className="card-visual-clr1">
          <p>Pathology Reports</p>
          <div className="img-icon">
            <img src={Pathology} alt="" />
            <p>3</p>
          </div>
        </div>
        <div className="card-visual-clr2">
          <p>Medical Images</p>
          <div className="img-icon">
            <div className="imge">
              <img src={Medical} alt="" />
            </div>
            <p>5</p>
          </div>
        </div>
        <div className="card-visual-clr3">
          <p>Vaccination</p>
          <div className="img-icon">
            <div className="imge">
              <img src={Vaccination} alt="" />
            </div>
            <p>4</p>
          </div>
        </div>
        <div className="card-visual-clr4">
          <p>Prescriptions</p>
          <div className="img-icon">
            <div className="imge">
              <img src={Prescriptions} alt="" />
            </div>
            <p>2</p>
          </div>
        </div>
        <div className="card-visual-clr5">
          <p>Hospitalization</p>
          <div className="img-icon">
            <div className="imge">
              <img src={Hospital} alt="" />
            </div>
            <p>1</p>
          </div>
        </div>

        <div className="card-visual-clr6">
          <p>Medical Expenses</p>
          <div className="img-icon">
            <div className="imge">
              <img src={Expenses} alt="" />
            </div>
            <p>3</p>
          </div>
        </div>
      </div>
      <div className="hr"></div>
      {/* bottm details  */}
      <div className="main-schedule">
        <div className="section-calendar">
          <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} customInput={<CustomCalendar />} />
        </div>
        <div className="section-Records">
          <CustomDropdown
            options={optionsRecord}
            onSelect={(option) => setSelectedRecord(option.value)}
            selectedValue={optionsRecord.find((opt) => opt.value === selectedRecord)?.label}
            placeholder="Select Record"
          />
        </div>
        <div className="section-parameters">
          <CustomDropdown
            options={optionsParameter}
            onSelect={(option) => setSelectedParameter(option.value)}
            selectedValue={optionsParameter.find((opt) => opt.value === selectedParameter)?.label}
            placeholder="Select Parameters"
          />
        </div>
        <div className="section-personal-health-record">
          <CustomDropdown
            options={optionsHealthRecord}
            onSelect={(option) => setSelectedHealthRecord(option.value)}
            selectedValue={optionsHealthRecord.find((opt) => opt.value === selectedHealthRecord)?.label}
            placeholder="Select Personal Health Record"
          />
        </div>
      </div>
      <div className="chart-container">
        <LineChart />
      </div>
    </div>
  );
};

export default DataVisualization;
