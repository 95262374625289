import React, { createContext, useContext, useState, useEffect} from "react";
const AuthContext = createContext();
export const useAuth = () => useContext(AuthContext);
const forge = require('node-forge');

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')) || null);
  const [accessToken, setAccessToken] = useState(localStorage.getItem('accessToken') || null);
  const [refreshToken, setRefreshToken] = useState(localStorage.getItem('refreshToken') || null);
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  
  const login = async (username, password) => {
    if (isLoggingIn) {
      return;
    }
    setIsLoggingIn(true);
    try {
      const publicKeyResponse = await fetch("https://dev3.backend.cohesivehealth.in/auth/publicKey", {
          method: "GET",
          headers: {
            "Content-Type": "application/json"
          }
        });
      if (!publicKeyResponse.ok) {
        throw new Error(`Login error: ${publicKeyResponse.status}`);
      }
      const publicKeyData = await publicKeyResponse.json();
      const publicKeyPem = await publicKeyData.publicKey;
      const publicKey = forge.pki.publicKeyFromPem(publicKeyPem);
      const encrypted = forge.util.encode64( publicKey.encrypt(password, 'RSA-OAEP'));
      console.log(publicKeyPem, encrypted)
      const url = "https://dev3.backend.cohesivehealth.in/auth/doctors/login";

      const body = {
        username: username,
        password: encrypted
      };
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
      };
      const response = await fetch(url, requestOptions);
      if (!response.ok) {
        throw new Error(`Login error: ${response.status}`);
      }
      const userInfo = await response.json();
      setUser(userInfo.doctor);
      setAccessToken(userInfo.accessToken);
      setRefreshToken(userInfo.refreshToken);
      localStorage.setItem('user', JSON.stringify(userInfo.doctor));
      localStorage.setItem('accessToken', userInfo.accessToken);
      localStorage.setItem('refreshToken', userInfo.refreshToken);
    } catch (e) {
      console.error(`Login error: ${e.message}`);
    } finally {
      setIsLoggingIn(false);
    }
  };

  const loginFromToken = async(refreshToken2) => {
    // console.log("REFRESH TOKEN",refreshToken2)
    const url = "https://dev3.backend.cohesivehealth.in/auth/doctors/login";
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "refreshtoken":`${refreshToken2}`
      }
    };
    try {
      const response = await fetch(url, requestOptions);
      if (!response.ok) {
        throw new Error(`Login error: ${response.status}`);
      }
      const userInfo = await response.json();
      setUser(userInfo.doctor);
      console.log('LOGINSUCSS FROM REFRESH',userInfo)
      setAccessToken(userInfo.accessToken);
      setRefreshToken(userInfo.refreshToken);
      localStorage.setItem('user', JSON.stringify(userInfo.doctor));
      localStorage.setItem('accessToken', userInfo.accessToken);
      localStorage.setItem('refreshToken', userInfo.refreshToken);
    } catch (e) {
      console.error(`REFRESH TOKEN Login error: ${e.message}`);
    }

  };

  const isLoggedIn = async () => {
    try {
      setIsLoggingIn(true);
      let userInfo = localStorage.getItem("user");
      let refreshToken1 = localStorage.getItem("refreshToken");
      userInfo = JSON.parse(userInfo);
      
      if (userInfo && refreshToken1) {
        await loginFromToken(refreshToken1);
        // console.log(user,"USER REFRESH")
      }
      setIsLoggingIn(false);
    } catch (e) {
      console.log(`isLogged in error ${e}`);
    }
  };

  useEffect(() => {
    isLoggedIn();
  }, []);

  const forgotPassword = async (oldPassword, newPassword, userType, identifier) => {
    const url = "https://dev3.backend.cohesivehealth.in/auth/changePassword";
    const body = {
      oldPassword: oldPassword,
      newPassword: newPassword,
      userType: userType,
      identifier: identifier
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    };
    try {
      const response = await fetch(url, requestOptions);
      if (!response.ok) {
        throw new Error(`Change password error: ${response.status}`);
      }
      alert("Password changed successfully");
    } catch (error) {
      alert(`Change password error: ${error.message}`);
    }
  };

  const logout = () => {
    setUser(null);
    setAccessToken(null);
    setRefreshToken(null);
    localStorage.removeItem('user');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
  };

  const value = {
    user,
    login,
    accessToken,
    logout,
    isLoggingIn,
    forgotPassword,
    setUser
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
